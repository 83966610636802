import Vue from "vue";

const clickOutside = Vue.directive("click-outside", {
  bind: (el, binding) => {
    const callBack = binding.value.callBack;

    el.__handler__ = (event) => {
      if (
        !el.contains(event.target) &&
        event.target.classList.value.indexOf(binding.value.toggleClass) === -1
      )
        callBack();
    };
    document.addEventListener("click", el.__handler__);
  },
  unbind: (el) => {
    document.removeEventListener("click", el.__handler__);
  },
});

export default {
  clickOutside,
};
