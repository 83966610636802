import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    auth: false,
    salesforce : false,
    theDefaultView: "topicsView",
    isEditedItems: false,
    navbar: {
      generateBot: false,
      saveActiveTopic: false,
      theActiveProjectTitle: false,
      trainBot: false
    },
    activeUser: {}
  },
  mutations: {
    setUser(state, payload) {
      state.activeUser = {...state.activeUser, ...payload};
    },
    setSalesForceView(state, status){
      state.salesforce = status;
    },
    setAuth(state, status) { // TODO: refactor needed
      state.auth = status;
    },
    /* Set the default view on active project page */
    setTheDefaultView(state, status) {
      state.theDefaultView = status;
    },
    /* Activate available items on navbar */
    setNavbar(state, status) {
      state.navbar = status;
    },
    isEditedItems(state, status) {
      state.isEditedItems = status;
    }
  },
  actions: {
    setUser({commit}, payload) {
      console.log("setuser action")
      commit('setUser', payload);
    }
  },
  getters: {
    activeUser(state){
      return state.activeUser
    }
  }
});

export default store;
