<template>
    <b-modal class="modal-dialog-centered modal-lg" ref="myModal" v-model="modalShow" @hidden="onHidden" @hide="onHide" size="400" hide-footer hide-header>
    <div class="d-flex flex-column" style="padding: 24px;">
        <div class="d-flex flex-row justify-content-between mb-4 align-items-start">                
            <BaseImportantIcon/>
            <button class="button-no-style" @click="modalShow=false"><BaseCloseIcon></BaseCloseIcon></button>
        </div>
        <div class="mb-2 supportingText">
            <span class="h4">Disable this user</span>
            <p class="mt-1">Are you sure you want to disable this user? This action cannot be undone.</p>
        </div>
        <div class="w-100 d-flex justify-content-center align-center mt-3" v-if="!loading">
            <button @click="modalShow=false" class="primary-border-button button-white height-44" style="width: 50%; margin-right: 6px; font-size: 16px;">{{$t('InviteUserModal.invite_collab_cancel')}}</button>
            <button class="button-accent500 primary-border-button height-44" style="width: 50%; margin-left: 6px; font-size: 16px;" @click="confirm()">Disable</button>
        </div>
    </div>
    </b-modal>
</template>

<script>
import BaseImportantIcon from "../base/BaseImportantIcon.vue";
import BaseInviteIcon  from "../base/BaseInviteIcon.vue"
import BaseCloseIcon from "../base/BaseCloseIcon.vue"
import Axios from "axios";
// import { response } from "express";

export default {
    components: {
        BaseInviteIcon,
        BaseCloseIcon,
        BaseImportantIcon
    },
    name: "ConfirmModal",
    props: {
        modalShow1: {
            type: Boolean,
            required: true
        },
        Title: {
            type: String,
            required: true
        },
        
    },
    data() {
        return {
            modalShow: this.modalShow1,
        };
    },
    watch: {
        modalShow1(newValue) {
            this.modalShow = newValue;
        }
    },
    methods: {
        onHidden() {
            this.$emit('input', false);
        },

        confirm(){
            this.$emit('confirmed', true);
            this.$emit('input', false);
        },
        
        onHide(){
        },
    }
}
</script>

<style lang="scss">
.modal-400{
    width: 400px !important;
}
</style>