<template>
  <div class="topicModelingTable">
    <!-- Show 'topicModelingTable' left side content. -->
   
      <!-- All topic Groups and topics Not grouped -->
      <div class="topicModelingTable__topicModelingWrapper">
        <div class="topicModelingTable__allTopics">
          <button :class="`topicModelingTable__topicNotGrouped${selectedGroup === -1 ? ' active': ''}`" @click="getTopics(-1)">{{ $t("All Topics") }}</button>
          <button :class="`topicModelingTable__topicNotGrouped${selectedGroup === null ? ' active': ''}`" @click="getTopics(null)">{{ $t("admin.topics.no_group_title") }}</button>
        </div>
      </div>

      <!---->
      <Alert
        :message="$t(alertMessage)"
        ref="alert"
      />
      <!-- GROUPS -->
      <div class="topicModelingTable__groupsWrapper">
        <div class= "topicModelingTable__groupList">
          <div v-for="(group) of orderedGroups" :key="group.id" class="topicModelingTable__groupList--chips">
            <button :class="`qa__tab--question${selectedGroup === group.id ? ' active': ''}`" @click="getTopics(group.id)" >{{ group.name }}</button> 
          </div>
        </div>
      </div>

      <div class="displayFlex">
        
        <input class="qa__moveQuestion--input flexGrow1" :placeholder="$t('admin.search')" @input="provideSearchedTopic($event.target.value)"/>

        <div class="topicModelingTable__topicModelingWrapper--buttons">

            <span :class="['topicModelingTable__modeDescription', { 'd-none': !isDeleteMode }]">{{ $t("ADMIN.TOPICS.DELETE.LABEL") }}</span>
            <span :class="['topicModelingTable__modeDescription', { 'd-none': !isMergeMode }]">{{ $t("ADMIN.TOPICS.MERGE.LABEL") }}</span> 
            <span :class="['topicModelingTable__modeDescription', { 'd-none': !isGroupMode }]">{{ $t("ADMIN.TOPICS.GROUP.LABEL") }}</span> 

            <div class="table__topics__merge">
              <button :class="['float-right', { 'is--active': isMergeMode }]" @click.prevent="handleMergeMode"><span class="text-hide">{{ $t("ADMIN.TOPICS.MERGE.BUTTON") }}</span></button>
            </div>

            <div class="table__topics__delete">
              <button :class="['float-right', { 'is--active': isDeleteMode }]" @click.prevent="handleDeleteMode"><span class="text-hide"></span></button>
            </div>

            <div class="table__topics__group">
              <button :class="['float-right', { 'is--active': isGroupMode }]" @click.prevent="handleGroupMode"><span class="text-hide"></span></button>
            </div>

            <div class="table__topics__create">
              <button class="float-right" @click="openModal('create-topic')"><span class="text-hide">{{ $t("ADMIN.TOPICS.CREATE.BUTTON") }}</span></button>
            </div> 
            


        </div>
  
      </div>
    <!-- TOPICS TABLE -->
      <div v-if="tableSpinner" class="spinner" >
        <img class="spinner--table" src="../../assets/aiku_spinner-2.gif" />
      </div>
      <div v-show="!tableSpinner" >
        <b-table-simple :borderless="true" class="topicModelingTable__table table-striped" responsive="true">
          <b-thead>
            <b-tr class="topicModelingTable__table__head">
              <b-th class="topicModelingTable__table__heading dig-font-weight-medium">{{ $t("admin.projects.topics.status") }}</b-th>
              <b-th class="topicModelingTable__table__heading dig-font-weight-medium">{{ $t("admin.projects.topics.name") }}</b-th>
              <b-th class="topicModelingTable__table__heading dig-font-weight-medium text-center"></b-th>
              <b-th class="topicModelingTable__table__heading dig-font-weight-medium text-center">{{ $t("admin.projects.topics.quality") }}</b-th>
              <b-th class="topicModelingTable__table__heading dig-font-weight-medium text-center">{{ $t("admin.projects.topics.items") }}</b-th>
              <b-th class="topicModelingTable__table__heading dig-font-weight-medium text-center">{{ $t("admin.projects.topics.lastEdit") }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="topic of orderedTopics" :class="['topicModelingTable__table__row dig-font-weight-medium', { 'is--added': topicsToMerge.indexOf(topic.id) !== -1 || topicsToDelete.indexOf(topic.id) !== -1 || topicsToGroup.indexOf(topic.id) !== -1 }]" 
              @click.prevent="isMergeMode ? handleTopicsToMerge(topic.id) : isDeleteMode ?  handleTopicsToDelete(topic.id) : isGroupMode ? handleTopicsToGroup(topic.id) : !topic.datetime_last_update ? updateTopicState(topic) : ''" :id="`topic-${topic.id}`" :key="topic.id">
              <b-td class="topicModelingTable__activation-icon"><button :class="`qa__list${topic.active ? '--activeTopicIcon' : '--disableTopicIcon'}`" @click.prevent="updateTopicVisibility(topic, topic.active ? false : true) "></button></b-td>
              <b-td class="topicModelingTable__table__placeholder" @click.prevent=" isMergeMode || isDeleteMode || isGroupMode ? '' : $emit('list-qas', topic)"><span class=" spacer" @keypress.enter="$event.target.blur()" @blur="editTopicName($event, topic.id)" >{{ topic.name }}</span></b-td>
              <b-td></b-td>
              <b-td class="topicModelingTable__table__cell text-center"><span :class="['topicModelingTable__qualityLabel', quality_status === 'DONE' ? topic.quality_label:'']">{{ quality_status === 'DONE' ? topic.quality_label : 'Computing...' }}</span></b-td>
              <b-td class="topicModelingTable__table__cell text-center">{{ `${topic.num_answers} A / ${topic.num_questions} Q` }}</b-td>
              <b-td class="topicModelingTable__table__cell text-center"><span v-if="topic.datetime_last_update">{{ topic.datetime_last_update | date}}</span><span v-else>{{ $t("admin.projects.topics.edited") }}</span></b-td>
              <b-td class="topicModelingTable__table__cell text-center"><button class="topicModelingTable__addToGroup-icon" @click="openModal('move-to-group')" @blur="saveTopicIdSelected(topic.id)" ></button></b-td>
            </b-tr>
          </b-tbody>
          <!-- Button to add a new project. -->
          <div v-if="!$store.state.salesforce">
            <button class="admin__addConversation" @click="openModal('query-classifier')">{{ $t("query-classifier.button_name") }}</button>
          </div>
        </b-table-simple>
      </div>
      <!-- Modal to create a new topic. -->
      <AdminModal
        :activeProjectId="activeProjectId"
        @cancel-action="closeModal"
        @confirm-action="createTopic"
        :createTopic="true"
        id="create-topic"
        name="admin.modals.name.label"
        ref="modal_4"
        title="admin.modals.create.topic.title"
        :type="$t('admin.topics.type')"
      />
      <!-- Modal to create a new group. -->
      <AdminModal
        :activeProjectId="activeProjectId"
        @cancel-action="closeModal"
        @confirm-action="groupTopic"
        :createGroup="true" 
        id="create-group"
        name="admin.modals.name.label"
        ref="modal_4"
        title="admin.topics.create_group"
        :type="$t('admin.topics.group')"
      />
      <!-- Move topic to group. -->
       <AdminModal
        :topicGroups="topicGroups"
        @cancel-action="closeModal"
        @confirm-action="updateTopicGroup"
        :moveToGroup="true"
        id="move-to-group"
        name="admin.modals.name.label"
        ref="modal_4"
        title="admin.topics.group_modal_title"
        :type="$t('admin.topics.group')"
      />
    <!--Modal to test query classifier -->
    <AdminQueryClassifierModal
      :activeProjectId="activeProjectId"
      @confirm-action="giveFeedback"
      @cancel-action="closeModal"
      title="Test"
      id="query-classifier"
      :type="'test'"
    />
  </div>
</template>

<script>

import { AdminModal, AdminQueryClassifierModal, Alert, Spinner } from "@/components";

import _ from "lodash";
import axios from "axios"; // TODO: refactor needed

export default {
  name: "TopicModelingTable",
  components: {
    AdminQueryClassifierModal,
    AdminModal,
    Alert,
    Spinner
  },
  props: {
    active: { default: Number, type: Number, required: true },
    files: { default: Array, type: Array, required: true },
    startClustersQuality: Boolean
  },
  data() {
    return {
      topics : [],
      topicIdSelected: null,
      topicsWithoutGroup:[],
      topicsOfTheSelectedGroup: [],
      activeProjectId: this.active,
      filesOrder: "desc",
      filesSort: "",
      isEditMode: false,
      isDeleteMode: false,
      isMergeMode: false,
      isGroupMode: false,
      selectedGroup: Number(sessionStorage.getItem("selectedGroupId")) > 0 ? Number(sessionStorage.getItem("selectedGroupId")) : -1,
      oldTopicName: "",
      topicsOrder: "desc",
      topicsSort: "id",
      topicGroups:[],
      topicsToDelete: [],
      topicsToMerge: [],
      topicsToGroup: [],
      spin: false,
      searchedTopic:'',
      alertMessage: "",
      quality_status: "DONE",
      tableSpinner: true,
      topicTableSuggestion: []
    }
  },
  created(){
    // if back button is pressed
    window.onpopstate = (event) => {
      this.setTheDefaultView();
    };
  },
  filters: {
    /**
     * Change the date default format.
     * @param {string} value - Date formatted in a full-text string format.
     */
    date(value) {
      const language = navigator.language || "en-US";
      let date = new Date(value);
      return date.toLocaleDateString(language, {
        day: "numeric",
        month: "long",
        year: "numeric"
      });
    }
  },
  methods: {
    /**
     * Close the selected modal.
     * @param {string} modal - ID of the selected modal.
     */
    closeModal(modal) {
      this.$bvModal.hide(modal);
    },
    /**
     * Open the selected modal.
     * @param {string} modal - ID of the selected modal.
     */
    openModal(modal) {
      this.$bvModal.show(modal);
    },

    provideSearchedTopic(e){
      this.searchedTopic = e;
      this.topicTableSuggestion = []
      for (let i of this.topicsOfTheSelectedGroup) {
        if(i.name.toLocaleLowerCase().includes(this.searchedTopic.toLocaleLowerCase()) && this.searchedTopic.length > 2){
          this.topicTableSuggestion.push(i);
          this.topicsOfTheSelectedGroup = this.topicTableSuggestion
          console.log(this.topicsOfTheSelectedGroup)
        }
        if(this.searchedTopic.length < 2){
          this.topicsOfTheSelectedGroup = this.topicsOfTheSelectedGroupORIGINAL
        }
      }

    },

    /*
     * COMPLEMENTARY METHOD OF train_classifier()
    */
    async check_training_status(counter){
      try {
        if((counter < 12)){
          const res = await axios.get(`project/${this.activeProjectId}/action/check-training`);
          if (res.status === 200 && res.data.data.status === "TRAINED"){
            this.spin = false;
            this.alertMessage= "training.end_training_message";
            this.$refs.alert.showAlert();
          }else{
            setTimeout(() => { this.check_training_status(counter); }, 5000);
          }
        }else{
          //TODO show error alert
          this.spin = false;
        }
      }
      catch(error){
        console.log(error);
      }
    },

    /*
     * THIS METHOD WILL LANCH THE TRAINING PROCESS AND THEN CHECK FOR A MAXIMUM OF 60 SECONDS
    */
    async train_classifier(){
      try {
        const res = await axios.post(`project/${this.activeProjectId}/action/train-classifier`);
        if (res.status === 200) {
          this.spin = true;
          this.alertMessage= "training.request_training_message"
          this.$refs.alert.showAlert()
          console.log("HO MOSTRATO L'ALERT PRIMA DEL TRAINING")
          setTimeout(() => { this.check_training_status(0); }, 5000);
        } else {
          //TODO show error alert
        }
      }
      catch(error){
        console.log(error);
      }
    },

    /**
     * Show feedback notification 
     */
    async giveFeedback(){
      this.alertMessage = "feedback";
      this.$refs.alert.showAlert()
    },
    /**
     * Create a new topic, then refresh the topics list.
     */
    async createTopic({
      name,
      project_id
    }) {
      await axios
              .put(`/topic`, {
                name,
                project_id
              })
                .then(() => {
                  this.getTopics(this.selectedGroup)
                  this.closeModal("create-topic");
                });
    },
    /**
     * Set the last edit date and time by updating the selected topic name.
     */
    async updateTopicState(topic) {
      try {
        await axios.post(`/topic/${topic.id}`, { name: topic.name });
      }
      catch {}
    },

    saveTopicIdSelected(_topicId){
      this.topicIdSelected = _topicId
    },

    async updateTopicGroup(_gruopId) {
      if(_gruopId === "null"){
        _gruopId = null;
      }
      try {
        await axios.post(`/topic/${this.topicIdSelected}`, { group_id: _gruopId });   
        this.getTopics(this.selectedGroup)
      }
      catch {}
    },
  
    async updateTopicVisibility(topic, isActive){
      await axios.post(`/topic/${topic.id}`, 
              {
                name: topic.name,
                active : isActive,
                group_id: topic.group_id
              })
              .then(() => {
                this.getTopics(topic.group_id)
                // this.$emit("train");
                // this.computeClustersQuality()
              });

    },

    /* AOperation on Table */
    handleGroupMode(){
      this.isMergeMode = false;
      this.topicsToMerge = [];
      this.isDeleteMode = false;
      if(this.isGroupMode){
        this.topicsToGroup = []
        this.isGroupMode = false;
      }
      else{
        this.isGroupMode = true;
      }
      
    },
    handleTopicsToGroup(id){
       if (!this.topicsToGroup.includes(id)) {
        this.topicsToGroup.push(id);
      } else {
        this.topicsToGroup = this.topicsToGroup.filter(item => { return item !== id });
      }
    },

    async groupTopic({name}){
      let groupName = name
      try {
        const res = await axios.put("/group", {
          project_id : this.activeProjectId,
          name : groupName
        });
        if (res.status === 200) {
          let topicgroups = await axios.get(`/project/${this.activeProjectId}/topicGroups`)
          this.topicGroups = topicgroups.data.data.topicGroups
          let group =  _.filter( this.topicGroups, g => g.name === groupName);
          var groupID = group[0].id
          await this.topicsToGroup.forEach(async topicID => {
            await axios.post(`/topic/${topicID}`, {
              group_id: groupID
            });
          });
        }
      }
      catch {}
      this.closeModal("create-group");
      this.topicsToGroup = []
      this.isGroupMode = false
      await this.getTopics(groupID)
    },

    handleDeleteMode() {
      this.isGroupMode = false;
      this.topicsToGroup = [];
      /*disable mergeMode if active*/ 
      if(this.isMergeMode){
        this.topicsToMerge = [];
        this.isMergeMode = false;
      }
      if (this.isDeleteMode ) {
        this.topicsToDelete = [];
        this.isDeleteMode = false;
      } else {
        this.isDeleteMode = true;
      }
    },
    handleTopicsToDelete(id){
       if (!this.topicsToDelete.includes(id)) {
        this.topicsToDelete.push(id);
      } else {
        this.topicsToDelete = this.topicsToDelete.filter(item => { return item !== id });
      }
    },
    /**
    * Merge two selected topics.
    * @param topics {array} - IDs of the two selected topics.
    */
    async deleteTopic() {
      if (this.isDeleteMode && this.topicsToDelete.length > 0) {
        
        let results = []
        try{
          for (let i=0; i < this.topicsToDelete.length; i++){
            const res = await axios.delete(`/topic/${this.topicsToDelete[i]}`);
            results.push(res)
          }
          if(results.filter(r => r.status === 200).length === this.topicsToDelete.length ){
            this.alertMessage= "admin.generate.delete_topic";
          }else{
            this.alertMessage= "admin.modals.upload.file.error";
          }
          this.$refs.alert.showAlert();
          this.isDeleteMode = false;
          this.topicsToDelete = [];
          this.getTopics(this.selectedGroup)
        }catch(err){
          console.log(err)
          this.isDeleteMode = false;
          this.topicsToDelete = [];
          this.getTopics(this.selectedGroup)
        }
        
      }
    },
    /**
     * Handle the merge mode.
     */
    handleMergeMode() {
      this.isGroupMode = false
      this.topicsToGroup = [];
      /*disable deleteMode if active*/ 
      if(this.isDeleteMode){
        this.topicsToDelete = [];
        this.isDeleteMode = false;
      }
      if (this.isMergeMode) {
        this.topicsToMerge = [];
        this.isMergeMode = false;
      } else {
        this.isMergeMode = true;
      }
    },
    /**
     * Add and/or remove the selected topic to and/or from the `this.topicsToMerge` array.
     * @param id {number} - ID of the selected topic.
     */
    handleTopicsToMerge(id) {
      if (this.topicsToMerge.length < 2 && !this.topicsToMerge.includes(id)) {
        this.topicsToMerge.push(id);
      } else {
        this.topicsToMerge = this.topicsToMerge.filter(item => { return item !== id });
      }
    },
    /**
     * Merge two selected topics.
     * @param topics {array} - IDs of the two selected topics.
     */
    async mergeTopics(topics) {
      if (this.isMergeMode && this.topicsToMerge.length === 2) {
        let [topic_1, topic_2] = this.topicsToMerge;
        // TODO: @federicomoretti • Implement TopicApi.
        try {
          const res = await axios.post("/topic/mergeTopics", {
            topic_1,
            topic_2
          });
          if (res.status === 200) {
            this.isMergeMode = false;
            /**not works if th group is selected */
            this.getTopics(this.selectedGroup)
            this.enterEditMode(res.data.data.id);
          }
        }
        catch {}
      }
    },

    /**
     * Execute the function to merge two topics on [Enter] keypress.
     * @event
     */
    doActionTopics(event) {
      if (event.keyCode === 13 && this.isMergeMode) {
        this.mergeTopics(this.topicsToMerge);
      }
      if (event.keyCode === 13 && this.isDeleteMode) {
        this.deleteTopic(this.topicsToDelete);
      }
      if(event.keyCode === 13 && this.isGroupMode){
        this.openModal('create-group')
      }
    },
    /**
     * Enter the edit mode on a topic after a successful merge of two.
     */
    enterEditMode(id) {
      this.isEditMode = true;
      const topic = document.getElementById(`topic-${id}`);
      topic.classList.add("is--added");
      const topicName = document.querySelector(`#topic-${id} > .topicModelingTable__table__placeholder .spacer`); // TODO: @federicomoretti • Give a shorter class name.
      this.$nextTick(() => {
        topicName.focus();
      });
      this.oldTopicName = topicName.innerHTML;
      topicName.innerHTML = "";
      topicName.contentEditable = true;
    },

    async getTopics(groupId){
      this.tableSpinner = true
      sessionStorage.setItem("selectedGroupId", groupId);
      this.selectedGroup = groupId
      let topics = []
      console.log("GROUPDID: " + this.selectedGroup)
      if(this.selectedGroup > 0){
        topics = await axios.get(`/group/${this.selectedGroup}/all-topics`)
      }else if (this.selectedGroup < 0) {
        topics = await axios.get(`/project/${this.activeProjectId}/topics`)
      }else{
        topics = await axios.get(`/project/${this.activeProjectId}/topics`)
        topics.data.data.topics = _.filter( topics.data.data.topics, g => g.group_id === null);
      }
      this.topics = topics.data.data.topics
      this.topicsOfTheSelectedGroup = topics.data.data.topics
      this.topicsOfTheSelectedGroupORIGINAL = this.topicsOfTheSelectedGroup
      this.tableSpinner = false
      this.$emit('spinner')
      
    },
    /**
     * Edit the topic name.
     * @event
     * @param id {number} - ID of the selected topic.
     */
    async editTopicName($event, id) {
      const topic = document.getElementById(`topic-${id}`);
      const topicName = $event.target;
      if (topicName.innerText) {
        try {
          await axios.post(`/topic/${id}`, {
            name: topicName.innerText
          });
        }
        catch{}
      } else {
        topicName.innerText = this.oldTopicName;
      }
      this.getTopics(this.selectedGroup)
      topic.classList.remove("is--added");
      topicName.contentEditable = false;
      this.oldTopicName = "";
      this.topicsToMerge = [];
      this.isEditMode = false;
    },

    setTheDefaultView(){
      this.$store.commit("setTheDefaultView", "topicsView");
    },

    async checkClustersQualityStatus(counter){
      try{
        const res = await axios.get(`/project/${this.activeProjectId}`)
        if (res.status === 200 && res.data.data.cluster_quality_status === "DONE"){
          this.quality_status = res.data.data.cluster_quality_status
          this.getTopics(this.selectedGroup)
        }else{
          setTimeout(() => { this.checkClustersQualityStatus(counter+1); }, 3000);
        }
      }
      catch(error){
        console.log(error);
      }
    },

    async computeClustersQuality(){
      
      try{
        const res = await axios.post(`/project/${this.activeProjectId}/action/compute_clusters_quality`)
        if (res.status === 200 && res.data.data.status === "ON_GOING"){
          this.quality_status = res.data.data.status
          let counter = 0
          setTimeout(() => { this.checkClustersQualityStatus(counter); }, 3000);
        }
        this.$emit('setToFalseComputeClusterQuality')
      }
      catch(error){
        console.log(error);
      }
    }
  },
  computed: {
    orderedTopics() {
      let availableTopics = this.topicsOfTheSelectedGroup;
      return availableTopics = this.topicsSort ? _.orderBy(availableTopics, this.topicsSort, this.topicsOrder) : availableTopics;
    },
    orderedGroups() {
      let availableGroups = this.topicGroups;
      return availableGroups = this.topicsSort ? _.orderBy(availableGroups, this.topicsSort, this.topicsOrder) : availableGroups;
    }
  },

  async beforeMount(){
    let topicgroups = await axios.get(`/project/${this.activeProjectId}/topicGroups`)
    this.topicGroups = topicgroups.data.data.topicGroups
    this.getTopics(this.selectedGroup)
  },
  mounted() {
    /**
     * Reset the active modals inputs.
     * @event bvModalEvt
     * @param {string} modalId - ID of the selected modal.
     */
    this.$root.$on("bv::modal::hide", (bvModalEvt, modalId) => {
      if (this.$refs.modal_4) {
        this.$refs.modal_4.form = {};
      }
    });
    /**
     * Set an event listener to execute the function to merge two topics.
     */
    window.addEventListener("keyup", this.doActionTopics, false);
    if(this.$store.state.isEditedItems){
      // this.computeClustersQuality();
    }
  },
  destroyed() {
    this.$store.commit("isEditedItems", false);
  },
  updated() {
    this.activeProjectId = this.active;
  },
  watch: {
    active(value) {
      this.activeProjectId = value;
    },
    startClustersQuality(){
      if(this.startClustersQuality){
        this.computeClustersQuality();
      }
    }
    
  },
  beforeDestroy() {
    /**
     * Remove the set event listener.
     */
    window.removeEventListener("keyup", this.doActionTopics, false);
  }
};
</script>
