import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { getInstance } from "@/auth/index";

import { API_URL, API_VERSION, RES_TENANT, RES_TENANTS, RES_USER, RES_USERS,
  ES_TEAM, RES_TEAMS, RES_TEAM_ACT_ADD_USERS, RES_TEAM_ACT_DELETE_USERS } from "@/common/config";

const ApiService = {
  /**
   * Init the base of each API request. Set the Auth0 authorization header
   */
  async init() {
    Vue.use(VueAxios, axios);
    console.log({API_URL})
    if(API_URL != "NONE"){
      Vue.axios.defaults.baseURL = API_URL + API_VERSION + "/";
    }else{
      Vue.axios.defaults.baseURL = "/api/" + API_VERSION + "/"
    }
  },

  /**
   * Set Auth0 token in Authorization header
   */
  async setAuthToken() {
    const authService = getInstance();
    const fn = async () => {
      // If the user is authenticated, set the token
      if (authService.isAuthenticated) {
        const token = await authService.getTokenSilently();
        Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
      return fn();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", (loading) => {
      if (loading === false) {
        return fn();
      }
    });
  },

  async get(resource, payload) {
    return Vue.axios.get(resource, payload).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  async post(resource, payload) {
    return Vue.axios.post(resource, payload).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  async delete(resource, payload) {
    return Vue.axios.delete(resource, payload).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  async put(resource, payload) {
    return Vue.axios.put(resource, payload).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;

export const TenantApi = {
  create(payload) {
    return ApiService.put(RES_TENANT, payload);
  },

  read(id) {
    console.log("Chiamato le tenant");
    return ApiService.get(RES_TENANT + "/" + id);
  },

  readAll() {
    return ApiService.get(RES_TENANTS);
  },

  update(id, payload) {
    return ApiService.post(RES_TENANT + "/" + id, payload);
  },

  delete(id) {
    return ApiService.delete(RES_TENANT + "/" + id);
  },
};

export const UserApi = {
  create(payload) {
    return ApiService.put(RES_USER, payload);
  },

  read(id) {
    return ApiService.get(`${RES_USER}/${id}`);
  },

  readAll(tenant_id) {
    return ApiService.get(`${RES_TENANT}/${tenant_id}/${RES_USERS}`);
  },

  update(id) {
    return ApiService.post(`${RES_USER}/${id}`);
  },

  delete(id) {
    return ApiService.delete(`${RES_USER}/${id}`);
  },
};

export const TeamApi = {
  create(payload) {
    return ApiService.put(RES_TEAM, payload);
  },

  read(id) {
    return ApiService.get(`${RES_TEAM}/${id}`);
  },

  readAll(tenant_id) {
    return ApiService.get(`${RES_TENANT}/${tenant_id}/${RES_TEAMS}`);
  },

  update(id, payload) {
    return ApiService.post(`${RES_TEAM}/${id}`, payload);
  },

  delete(id) {
    return ApiService.delete(`${RES_TEAM}/${id}`);
  },

  addUsers(id, payload) {
    return ApiService.post(
      `${RES_TEAM}/${id}/${RES_TEAM_ACT_ADD_USERS}`,
      payload
    );
  },

  deleteUsers(id, payload) {
    return ApiService.post(
      `${RES_TEAM}/${id}/${RES_TEAM_ACT_DELETE_USERS}`,
      payload
    );
  },
};