<template>
  <b-navbar class="navbar navbar-main" :fixed="isFixed" type="light">
    <div v-if="$store.state.navbar.theActiveProjectTitle"> <!-- TODO: refactor needed -->
    </div>
  </b-navbar>
</template>

<script>
import { Alert } from "@/components";
import axios from "axios"; // TODO: refactor needed
import bus from "@/bus";
import icons from "@/styles/_icons.scss";
import utils from "@/common/utils";

export default {
  name: "Navbar",
  components: {
    Alert
  },
  props: {
    active: { default: Object, type: Object },
    isFixed: { default: "top", type: String },
    projectSettings: { default: "noProjects", type: String },
  },
  data() {
    return {
      editedItems: [],
      isEdited: false,
      navbarBrandIcon: icons.navbarBrandIcon,
    }
  },
  created() {
    bus.$on("edited", item => {
      this.editedItems.push(item);
      this.isEdited = true;
    });
    bus.$on("saved", () => {
      this.isEdited = false;
    });
  },
  methods: {
    /**
     * Open the selected modal.
     * @param {string} modal - ID of the selected modal.
     */
    openModal(modal) {
      this.$bvModal.show(modal);
    },
    /**
     * Save local questions and/or answers changes to the database.
     * @param {array} items - Array of edited questions and/or answers.
     */
    async saveChanges(items) {
      if (!items) return;

      let topic_id, text, is_training, is_selected;
      
      for (let item of items) {

        if (item.type === "question") {
          topic_id = item.topic_id;
          text = item.text;
          is_training = true;
          await axios
                .post(`/question/${item.id}`, {
                  topic_id,
                  text,
                  is_training
                })
                  .then(() => {
                    this.editedItems = [];
                    bus.$emit("saved");
                    this.$refs.alert.showAlert();
                  });
        } else {
          topic_id = item.topic_id;
          text = item.text;
          is_selected = true;
          await axios
                .post(`/answer/${item.id}`, {
                  topic_id,
                  text,
                  is_selected
                })
                  .then(() => {
                    this.editedItems = [];
                    bus.$emit("saved");
                    this.$refs.alert.showAlert();
                  });
        } 
      }
    },
    /**
     *
     */
    trainBot() {
      // TODO: @federicomoretti
    },
    async createNewBot() {
      await axios
              .post(`/project/${this.active.id}/action/export-topics`, {
                responseType: "blob"
              })
              .then(res => {
                const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
                const fileLink = document.createElement("a");
                fileLink.setAttribute("download", "topics.csv");
                fileLink.href = fileUrl;
                document.body.appendChild(fileLink);
                fileLink.click();
              });
    }, 
  },
  beforeMount() {
    this.navbarBrandIcon = utils.fixSvgImport(this.navbarBrandIcon);
  }
};
</script>