import store from "@/store";
import cookies from "@/common/cookies";
import axios from "axios"; 

const check_auth = () => {
  if (localStorage.getItem("jwt") || sessionStorage.getItem("jwt") || cookies.getCookie("jwt")) {
    store.state.auth = true;
  }
};

async function perform_auth(id, token, next) {
  try {
    const res = await axios.get("/user/" + id, {headers: { Authorization: `Bearer ${token}` }});
      if (res.status === 200) {

        console.log("AUTHENTICATION PERFORMED: " + res.status)
        
        /**
         * Save the user token in either a `localStorage` or a `sessionStorage` object.
         */
        localStorage.setItem("jwt", token);
        sessionStorage.setItem("jwt", token);
        cookies.setCookie("jwt", token);
        localStorage.setItem("id", id);
        sessionStorage.setItem("id", id);
        cookies.setCookie("id", id);
        store.state.auth = true;
        store.state.salesforce = true;
        /**
         * Redirect
         */
        next();
      } else {
        console.log("AUTHENTICATION FAILED")
        next({
          path: "/login"
        });
      }
    } catch {
      console.log("AUTHENTICATION FAILED")
      next({
        path: "/login"
      });
    }
}

const routes = [{
  path: "/",
  redirect: "/login"
},
{
  path: "/login",
  name: "login",
  beforeEnter: (to, from, next) => {
    check_auth();
    if (store.state.auth) {
      next({
        path: "/admin"
      });
    } else {
      next();
    }
  },
  component: () => import("@/views/Login")
},
{
  path: "/admin",
  name: "admin",
  beforeEnter: (to, from, next) => {
    check_auth();
    if (!store.state.auth) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  },
  component: () => import("@/views/Admin")
},
{
  path: "/reset-pw/token/:jwt",
  name: "resetPsw",
  component: () => import("@/views/ResetPsw")
},
{
  path: "/oauth/:user_id/:jwt",
  name: "oauth",
  beforeEnter: (to, from, next) => {
    perform_auth(to.params.user_id, to.params.jwt, next);
  },
  component: () => import("@/views/Projects")
},
{
  path: "/manage-organizations",
  name: "manage-organizations",
  beforeEnter: (to, from, next) => {
    check_auth();
    if (!store.state.auth) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  },
  component: () => import("@/views/ManageOrganizations")
},
{
  path: "/setup-account/token/:jwt",
  name: "oauth",
  component: () => import("@/views/SignUp")
},
{
  path: "/admin/:user_id/:project_id/:jwt",
  name: "admin",
  beforeEnter: (to, from, next) => {
    perform_auth(to.params.user_id, to.params.jwt, next);
  },
  component: () => import("@/views/AdminSalesforce")
},
{
  path: "/analytics-agents",
  name: "analytics-agents",
  beforeEnter: (to, from, next) => {
    check_auth();
    if (!store.state.auth) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  },
  component: () => import("@/views/AgentsAnalytics")
},
{
  path: "/projects/:user_id/:jwt",
  name: "projects",
  beforeEnter: (to, from, next) => {
    perform_auth(to.params.user_id, to.params.jwt, next);
  },
  component: () => import("@/views/Projects")
},
{
  path: "/projects",
  name: "projects",
  beforeEnter: (to, from, next) => {
    check_auth();
    if (!store.state.auth) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  },
  component: () => import("@/views/Projects")
},
{
  path: "/analytics-bot",
  name: "analytics-bot",
  beforeEnter: (to, from, next) => {
    check_auth();
    if (!store.state.auth) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  },
  component: () => import("@/views/BotAnalytics")
},
{
  path: "/project-settings",
  name: "project-settings",
  beforeEnter: (to, from, next) => {
    check_auth();
    if (!store.state.auth) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  },
  component: () => import("@/views/ProjectSettings")
},
{
  path: "/manage-users",
  name: "manage-users",
  beforeEnter: (to, from, next) => {
    check_auth();
    if (!store.state.auth) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  },
  component: () => import("@/views/ManageUsers")
},
{
  path: "/demo",
  name: "demo",
  beforeEnter: (to, from, next) => {
    check_auth();
    if (!store.state.auth) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  },
  component: () => import("@/components/aiknowu/TheDemoFaq/TheDemoFaq"),
  children: [
    {
      path: "",
      component: () => import("@/components/aiknowu/TheDemoFaq/components/home")
    },
    {
      path: ':category',
      component: () => import("@/components/aiknowu/TheDemoFaq/components/category")
    },
    {
      path: ':category/:id',
      component: () => import("@/components/aiknowu/TheDemoFaq/components/topic")
    }
  ]
},
{
  path: "/qa/:id",
  name: "Qa",
  beforeEnter: (to, from, next) => {
    check_auth();
    if (!store.state.auth) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  },
  component: () => import("@/views/Qa")
},
{
  path: "/:catchAll(.*)",
  name: "NotFound",
  beforeEnter: (to, from, next) => {
    check_auth();
    if (!store.state.auth) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  },
    component: () => import("@/views/errors/404")
  },

];

export default routes;