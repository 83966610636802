const cookies = {
  /**
   * Set a fallback two-years cookie.
   * @param {string} name - Name of the new cookie.
   * @param {string} value - Value of the new cookie.
   */
  setCookie(name, value) {
    // eslint-disable-next-line no-undef
    const domain = process.env.VUE_APP_BASE_URL_APP || ""; // TODO: @federicomoretti · add a valid domain in `..env`, then remove `// eslint-disable-next-line no-undef`
    let expires = "";
    let date = new Date();
    date.setTime(date.getTime()+(365*24*60*60*1000*2)); // set a two-years cookie
    expires = date.toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; domain=${domain}; path=/; HttpOnly; secure`;
  },
  /**
   * Get all the available cookies, then select the specified one.
   * @param {string} name - Name of the cookie to select.
   */
  getCookie(name) {
    if (!document.cookie ||document.cookie === undefined || document.cookie === null || document.cookie === "") return;
    try{
      return document.cookie.split(";").find(row => row.startsWith(name)).split("=")[1];
    }catch(e) {
      console.log(e);
      return
    }
  },
  /**
   * Delete the selected cookie.
   * @param {string} name - Name of the selected cookie.
   */
  deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
  }
};
export default cookies;