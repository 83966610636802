<template>
  <b-modal centered class="adminModal" size="lg" hide-footer hide-header hide-header-close :id="modal.id" no-close-on-backdrop no-close-on-esc >
    <!-- TODO: refactor needed -->
    <span class="adminModal__title dig-font-weight-bold">{{ $t(modal.title) }}</span>
    <b-form class="adminModal__form" @submit.prevent="checkForm">
      <div class="adminModal__content">
        <div v-if="modal.id === 'query-classifier'">
          <b-form-group id="input-group-1">
            <b-form-input class="adminModal__content__input dig-font-weight-medium" :placeholder="$t('query-classifier.placeholder')" required v-model="input_test_sentence"></b-form-input>
          </b-form-group>
          <b-form-group v-if= 'classifier.classifier_response' id="input-group-1">
            <b-list-group-item>
              <h6 class="dig-font-weight-bold">{{$t('query-classifier.topic')}}</h6>
              <Spinner :start = 'spin'/>
                {{classifier.classifier_response_topic}}            
              </b-list-group-item>
            <b-list-group-item>  
              <h6 class="dig-font-weight-bold">{{$t('query-classifier.suggested_response')}}</h6>
              <Spinner :start = 'spin'/>
              <b-form-textarea plaintext :value="classifier.classifier_suggested_response" class="queryClassifierModal__content__textarea" size="sm" no-resize></b-form-textarea>
            </b-list-group-item>
            <b-list-group-item>  
              <h6 class="dig-font-weight-bold">{{$t('query-classifier.probability')}}</h6>
              <Spinner :start = 'spin'/>
              {{classifier.classifier_probability}}
            </b-list-group-item>
            <b-button class="float-right dig-font-weight-medium"  @click="giveFeedBackClassifier(false)" style="margin-left:15px" variant="white"><img class="queryClassifierModal" :src="dislike"></b-button>
            <b-button class="dig-font-weight-medium float-left" @click="giveFeedBackClassifier(true)"  variant="white"><img class="queryClassifierModal" :src="like"></b-button>
          </b-form-group>
        </div>
      </div>
      <div class="adminModal__footer">
        <b-button class="adminModal__footer__cancel float-left" @click="closeModal" variant="outline-dark">{{ changesWarning ? $t("admin.modals.discard") : $t("admin.modals.cancel") }}</b-button>
        <b-button class="adminModal__footer__confirm float-right" :disabled="classifier.classifier_response ? true : false"  type="submit"  variant="dark">{{ changesWarning ? $t("admin.modals.save") : $t("admin.modals.confirm") }}</b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import axios from "axios"; // TODO: refactor needed
import {Spinner} from  "@/components" 
import icons from "@/styles/_icons.scss";
import utils from "@/common/utils";
import _ from "lodash";
export default {
  name: "AdminModal",
  components: {Spinner},
  props: {
    activeProject: { default: Object, type: Object },
    activeProjectId: { default: Number, type: Number },
    activeTopicId: { default: Number, type: Number },
    activeTopicTitle: { default: String, type: String },
    activeUser: { default: Object, type: Object },
    changesWarning: { default: Boolean, type: Boolean },
    createProject: { default: Boolean, type: Boolean },
    createTopic: { default: Boolean, type: Boolean },
    description: { default: String, type: String },
    deleteFile: { default: Boolean, type: Boolean },
    deleteTopic: { default: Boolean, type: Boolean },
    editProject: { default: Boolean, type: Boolean },
    editTopic: { default: Boolean, type: Boolean },
    id: { default: String, type: String, required: true },
    name: {default: String, type: String },
    title: { default: String, type: String, required: true },
    type: { default: String, type: String },
    uploadFile: { default: Boolean, type: Boolean },
    uploadedFile: { default: Number, type: Number }
  },
  data() {
    return {
      file: [],
      form: {
        confirm: "",
        name: "",
        description: ""
      },
      modal: {
        description: this.description,
        id: this.id,
        name: this.name,
        title: this.title,
        type: this.type
      },
      input_test_sentence:'',
      classifier: {
        classifier_response : false,
        classifier_response_topic:'',
        classifier_response_suggested_response:'',
        classifier_response_probability:'',
        classification_id: ''
      },
      spin: false,
      dislike: icons.dislikeIcon,
      like: icons.likeIcon
    }
  },
  created() {
  },
  methods: {
    /**
     * Close the selected modal.
     */
    closeModal() {
      this.$emit("cancel-action", this.modal.id);
      this.classifier.classifier_response = false
    },

    async queryClassifier(){
      try{
        this.spin = true
        const res = await axios.post(`project/${this.activeProjectId}/action/query-classifier-bot`, { sentence: this.input_test_sentence, threshold:0.1, channel_id: 0});
        this.spin = false
        let result =_.maxBy(res.data.data, function(o) { return o.probability; });
        this.classifier.classifier_response_topic = result.topic
        this.classifier.classifier_suggested_response = result.suggested_response
        this.classifier.classifier_probability = result.probability.toFixed(2).concat('%').substr(2)
        this.classifier.classification_id = result.classification_id 
      } 
      catch(error)
      { 
        console.log(error)
      }
    },

    async giveFeedBackClassifier(value){
      try{
        this.closeModal()
        this.$emit("confirm-action");
        
        const res = await axios.post(`/classification/${this.classifier.classification_id}/action/feedback-classifier-bot`, { feedback: value});
        if (res.status === 200){
          this.closeModal()
          this.$emit("confirm-action");
        }
      } 
      catch(error)
      { 
        console.log(error)
      }
    },

    /**
     * Validate the form inputs, then execute the proper logics.
     */
    checkForm() {
      if (this.type === 'test') {
        this.queryClassifier();
        this.classifier.classifier_response = 'true'
      }
    }
  },

  beforeMount() {
    this.dislike = utils.fixSvgImport(this.dislike);
    this.like = utils.fixSvgImport(this.like);
  },

  mounted() {
    /**
     * Reset the active modals inputs.
     * @event bvModalEvt
     * @param {string} modalId - ID of the selected modal. 
     */
    this.$root.$on("bv::modal::show", (bvModalEvt, modalId) => {
      this.input_test_sentence = ''
      this.classifier.classifier_response_topic = ''
      this.classifier.classifier_suggested_response = ''
      this.classifier.classifier_probability = ''
      this.classifier.classification_id = ''
    });
  }
};
</script>