<template lang="">
    <div class="action-bar d-flex align-items-center justify-content-start inactive" :class="activeClass"> 
      <slot name="content"></slot>
    </div>
</template>

<script>
export default {
    props: {
      active: Boolean
    },
    computed: {
      activeClass() {
        if (this.active == true){
          console.log("ciao")
          return "active"
        }
      }
    }
}
</script>

<style lang="scss" scoped>
@import '../../styles/colors';
    .action-bar{
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        height: 70px;
        width: 100%;
        background-color: var(--primary400);
        color: white;
        font-weight: 600;
        transition: 0.15s ease-in-out all;
        padding: 0 60px;
        z-index: 2;
    }

    .inactive{
      transform: translateY(-100%);
    }

    .active{
      transform: translateY(0%);
      //box-shadow: rgba(126, 126, 126, 0.568) 0px 4px 4px;
    }

</style>