<template>
  <b-modal centered class="adminModal" hide-footer hide-header hide-header-close :id="modal.id" no-close-on-backdrop no-close-on-esc>
    <!-- TODO: refactor needed -->
    <span class="adminModal__title">{{ $t(modal.title) }}</span>
    <b-form class="adminModal__form" @submit.prevent="checkForm">
      <div class="adminModal__content">
        <div v-if="createProject || createTopic || editProject || editTopic || createChannel || createGroup">
          <b-form-group id="input-group-1" :label="$t(modal.name)">
            <b-form-input :maxlength="max" class="adminModal__content__input dig-font-weight-medium" :placeholder="(modal.id === 'create-project' || modal.id === 'create-topic' || modal.id ==='create-channel' || modal.id ==='create-group') 
              ? $t('admin.modals.name.placeholder', { type: modal.type }) : ''" required type="text" v-model="form.name" :value="form.name"></b-form-input>
          </b-form-group>
          <div v-if="createProject || editProject">
            <b-form-group id="input-group-2" :label="$t(modal.description)">
              <b-form-input class="adminModal__content__input dig-font-weight-medium" :placeholder="modal.id === 'create-project' ? $t('admin.modals.description.placeholder') : ''" type="text" v-model="form.description" :value="form.description"></b-form-input>
            </b-form-group>
            <span class="dig-font-weight-bold">{{$t('admin.modals.lang')}}</span>
            <div class="select" style="width:20%">
              <select @change="checkLang($event)">
                <option class="adminModal__checkboxList">italiano</option>
                <option class="adminModal__checkboxList">english</option>
                <option class="adminModal__checkboxList">español</option>
                <option class="adminModal__checkboxList">deutsch</option>
                <option class="adminModal__checkboxList">français</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="changesWarning || deleteFile || deleteProject || deleteTopic || deleteChannel">
          <div v-if="changesWarning">
            <p class="adminModal__content__warning dig-font-weight-bold">{{ $t("admin.modals.changes.warning") }}</p>
          </div>
          <div v-if="deleteTopic">
            <p class="adminModal__content__warning dig-font-weight-bold">{{ $t("admin.modals.delete.topic.warning")}}</p>
          </div>
          <div v-if="deleteFile">
            <p class="adminModal__content__warning dig-font-weight-bold">{{ $t("admin.modals.delete.file.warning") }}</p>
          </div>
           <div v-if="deleteProject">
            <p class="adminModal__content__warning dig-font-weight-bold">{{ $t("admin.modals.delete.project.warning")}}<br><br>{{ $t("admin.modals.delete.project.warning_2") }} <span class="color--magenta">{{ activeProject.name }}</span> {{ $t("admin.modals.delete.project.warning_3") }}</p>
          </div>
          <div v-if="deleteProject">
            <b-form-group id="input-group-1">
              <b-form-input class="adminModal__content__input dig-font-weight-medium" :placeholder="$t('admin.modals.delete.project.placeholder')" required type="text" v-model="form.confirm"></b-form-input>
            </b-form-group>
          </div>
          <div v-if="deleteChannel">
            <b-form-group id="input-group-1">
              <b-form-input class="adminModal__content__input dig-font-weight-medium" :placeholder="$t('admin.modals.delete.channel.placeholder')" required type="text" v-model="form.name"></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div v-if="modal.id === 'upload-file' || modal.id === 'import-file'">
          <div class="adminModal__content">
            <b-form-group id="input-group-1">
              <input @change="handleFileUpload" ref="file" name="file" type="file" />
            </b-form-group>
          </div>
        </div>
        <!-- Move topic to group-->
        <div v-if="moveToGroup">
          <div class="select">
            <select id="standard-select" @change="check($event)">
              <option value="null" ><label> {{$t("admin.topics.no_group")}}</label></option>
              <option class="adminModal__checkboxList" v-for="(group) in topicGroups" :key="group.id" :value="group.id">
                <label >{{group.name}}</label>
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="adminModal__footer">
        <b-button class="adminModal__footer__cancel float-left" @click="closeModal" variant="outline-dark">{{ changesWarning ? $t("admin.modals.discard") : $t("admin.modals.cancel") }}</b-button>
        <b-button class="adminModal__footer__confirm float-right" type="submit"  variant="dark">{{ changesWarning ? $t("admin.modals.save") : $t("admin.modals.confirm") }}</b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import axios from "axios"; // TODO: refactor needed
import {Spinner} from  "@/components" 
import icons from "@/styles/_icons.scss";
export default {
  name: "AdminModal",
  components: {Spinner},
  props: {
    activeProject: { default: Object, type: Object },
    activeProjectId: { default: Number, type: Number },
    activeTopicId: { default: Number, type: Number },
    activeTopicTitle: { default: String, type: String },
    activeUser: { default: Object, type: Object },
    changesWarning: { default: Boolean, type: Boolean },
    createProject: { default: Boolean, type: Boolean },
    createChannel: { default: Boolean, type: Boolean },
    createGroup: { default: Boolean, type: Boolean },
    createTopic: { default: Boolean, type: Boolean },
    description: { default: String, type: String },
    deleteFile: { default: Boolean, type: Boolean },
    deleteProject: { default: Boolean, type: Boolean },
    deleteTopic: { default: Boolean, type: Boolean },
    deleteChannel: { default: Boolean, type: Boolean },
    editProject: { default: Boolean, type: Boolean },
    editTopic: { default: Boolean, type: Boolean },
    id: { default: String, type: String, required: true },
    name: {default: String, type: String },
    moveToGroup: { default: Boolean, type: Boolean },
    title: { default: String, type: String, required: true },
    type: { default: String, type: String },
    topicGroups: {  default: Array, type: Array},
    uploadFile: { default: Boolean, type: Boolean },
    uploadedFile: { default: Number, type: Number }
  },
  data() {
    return {
      groupIdSelected:null,
      file: [],
      form: {
        confirm: "",
        name: "",
        description: "",
        lang:"it"
      },
      modal: {
        description: this.description,
        id: this.id,
        name: this.name,
        title: this.title,
        type: this.type
      },
       max: 35,
    }
  },
  created() {
    if (this.modal.id === 'edit-project') {
      this.form.name = this.activeProject.name;
      this.form.description = this.activeProject.description;
    } else {
      this.form.name = this.activeTopicTitle;
    }
  },
  methods: {

    check(e){
      this.groupIdSelected = e.target.value;
      console.log(this.groupIdSelected)
    },

    checkLang(e){
      this.form.lang = e.target.value
      this.form.lang =  this.form.lang.substring(0, 2);
      console.log(this.form.lang)
    },
    /**
     * Close the selected modal.
     */
    closeModal() {
      this.$emit("cancel-action", this.modal.id);
    },
    /**
     * Handle the single-file uploading.
     */
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    /**
     * Validate the form inputs, then execute the proper logics.
     */
    checkForm() {
      if (this.createProject || this.editProject) {
        if (this.form.name) {
          this.$emit("confirm-action", {
            description: this.form.description || "",
            name: this.form.name,
            lang: this.form.lang,
            user_id: this.activeUser.id
          });
        }
      } else if (this.createTopic || this.editTopic) {
        if (this.form.name) {
          this.$emit("confirm-action", {
            id: this.activeTopicId,
            name: this.form.name,
            project_id: this.activeProjectId,
          })
        }
      } else if (this.createChannel || this.createGroup) {
        if (this.form.name) {
          this.$emit("confirm-action", {
            name: this.form.name,
          })
        }
      } else if (this.deleteChannel) {
        if (this.form.name) {
          this.$emit("confirm-action", {
            name: this.form.name,
          })
        }
      } else if (this.deleteFile) {
        this.$emit("confirm-action", this.uploadedFile);
      } else if (this.deleteProject) {
        if (this.form.confirm === this.activeProject.name) {
          this.$emit("confirm-action", this.activeProject.id);
        }
      } else if (this.deleteTopic) {
        this.$emit("confirm-action", this.activeTopicId);
      } else if (this.uploadFile) {
        this.$emit("confirm-action", this.file);
        this.closeModal(); 
      } else if (this.changesWarning) {
        this.$emit("confirm-action");
      } else if (this.moveToGroup) {
        this.$emit("confirm-action", this.groupIdSelected);
        this.closeModal(); 
        this.groupIdSelected = null
      }
    }
  },

  mounted() {
    /**
     * Reset the active modals inputs.
     * @event bvModalEvt
     * @param {string} modalId - ID of the selected modal. 
     */
    this.$root.$on("bv::modal::show", (bvModalEvt, modalId) => {
      if (this.modal.id === 'edit-project') {
        this.form.name = this.activeProject.name;
        this.form.description = this.activeProject.description;
      }
    });
  }
};
</script>