<template>
    <span class="badge" :class="[statusClass, opacity ? 'opacity0' : 'opacity1']">
        <img v-if="addAction" src="../../assets/icon_plus.svg" alt="">
        {{statusText}}
        <img v-if=deleteAction class="ml-2" src="../../assets/icon_close.svg" style="opacity: 0.60; height: 12px;"/>
    </span>
</template>

<script>
export default {
    name: "BaseBadge",
    props: {
        status: {
            required: true,
            type: String
        },
        value: {
            required: false,
            type: String
        },
        deleteAction: {
            required: false,
            type: Boolean
        },
        addAction: {
            required: false,
            type: Boolean
        },
        opacity: {
            required: false,
            type: Boolean
        }   
    },
    computed: {
        statusClass() {
            return this.status.toLowerCase() //I simply return the string class
        },
        statusText(){
            if (this.value || this.addAction){
                return this.value
            } else return this.status.charAt(0).toUpperCase() + this.status.slice(1).toLowerCase() //The char will be uppercase now
        }
    }
}
</script>

<style lang="scss" scoped>
    .opacity{
        opacity: 0;
    }
    .badge{
        display: flex;
        flex-direction: row;
        //justify-content: center;
        align-items: center;
        padding: 2px 10px;
        width: fit-content;
        height: 22px;
        border-radius: 16px;
        font-weight: 500;
        font-size: 12px;
    }
    .active{
        background: #ECFDF3;
        color: #027A48;
    }
    .inactive{
        background: #F6F5F5;
        color: #101214;
    }
    .pending{
        background-color: #FFFAEB;
        color: #B54708;
    }
    .project{
        background: #F2F4F7;
        color: #344054;
        transition: all 0.2s ease;
        cursor: pointer;
        &:hover{
            background: #dbdee4;
        }
        max-width: 6rem;
        overflow: hidden;
    }
    .counter{
        background: #F9F5FF;
        color: #6941C6;
    }
    .disabled{

    }
    .good{

    }

    .project{

    }
</style>