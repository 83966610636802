<template lang="">
    <b-modal class="modal-dialog-centered modal-lg" ref="myModalProjects" v-model="modalShow" @hidden="onHidden" @hide="onHide" size="600" hide-footer hide-header>
    <div class="d-flex flex-column inviteUserModal" style="padding: 24px;">
        <div class="d-flex flex-row justify-content-between mb-4 align-items-start">                
            <BaseInviteIcon/>
            <button class="button-no-style" @click="modalShow=false"><BaseCloseIcon></BaseCloseIcon></button>
        </div>
        <div class="mb-4 supportingText">
            <span class="h5" v-if="activeUser">{{$t('AssignProjectModal.title')}} {{activeUser.email}}</span>
            <p class="mt-1">{{$t('AssignProjectModal.description')}}</p>
        </div>
        <div class="d-flex flex-wrap">
            <div role="button" v-for="project in appliedProjects" :key="'project-' + project.id" class="assign-project" style="width: 50%; display: flex" @click="toggleCheckbox(project)">
                <span :title="project.name" style="width: 10rem; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                    {{ project.name }}
                </span>
                <b-form-checkbox
                    :id="'checkbox-' + project.team_id"
                    v-model="project.active"
                    :name="project.name"
                    @click.stop
                >
                </b-form-checkbox>
            </div>
        </div>
        <div style="height: 100%; width: 100%; padding: 2rem;" class="d-flex align-items-center justify-content-center" v-if="loading">
            <div class="spinner-border d-flex justify-content-center" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-center align-center mt-5" v-else>
            <button class="button-accent500 primary-border-button height-44" style="width: 50%; margin-left: 6px; font-size: 16px;" @click="applyChanges()">{{$t('AssignProjectModal.apply')}}</button>
        </div>
    </div>
    </b-modal>
</template>
<script>
import BaseInviteIcon  from "../base/BaseInviteIcon.vue"
import BaseCloseIcon from "../base/BaseCloseIcon.vue"
import axios from "axios";


export default {
    components: {
        BaseInviteIcon,
        BaseCloseIcon
    },
    name: "AssignProjectModal",
    props: {
        modalShow1: {
            type: Boolean,
            required: true
        },
        inputField: {
            type: String
        },
        mutliselect: {
            type: Boolean,
            required: false
        },
        activeUser: {
            type: Object,
            required: false
        },
        selectedUsers: {
            type: Array,
            required: false
        },
        org_id: {
            required: true
        }
    },
    data(){
        return{
            loading: false,
            modalShow: this.modalShow1,
            status: true,
            projects: null,
            projectsList: [],
            selectedProjects: [],
            appliedProjects: null
        }
    },
    watch: {
        modalShow1(newValue) {
            this.modalShow = newValue;
        },
        activeUser(){
            this.projects = JSON.parse(JSON.stringify(this.getActiveProjectList()))
            this.appliedProjects = JSON.parse(JSON.stringify(this.projects))
        }
    },
    computed: {

    },
    mounted(){
        this.getProjectsList()
    },
    methods: {
        removeFromProject(projectId){
            let body = {
                users: [this.activeUser.id]
            }
            axios.post('/team/'+projectId+'/action/remove-users', body)
                .then( response => {
                    console.log(response)
                })
                .catch( error => {
                    console.log(response)
                })
        },
        addToProject(projectId){
            let body = {
                users: [this.activeUser.id]
            }
            axios.post('/team/'+projectId+'/action/add-users', body)
                .then( response => {
                    console.log(response)
                })
                .catch( error => {
                    console.log(response)
                })
        },
        toggleCheckbox(appliedProject) {
            appliedProject.active = !appliedProject.active;
        },
        getActiveProjectList() {
            const activeUserIds = this.activeUser.user_projects.map(item => item.team_id);
            const resultArray = this.projectsList.map(item => {
                return {
                    ...item,
                    active: activeUserIds.includes(item.team_id)
                };
            });
            return resultArray
        },

        async applyChanges() {
        try {
            this.loading = true;
            let modifiedProjects = [];
            for (let i = 0; i < this.appliedProjects.length; i++) {
                if (this.appliedProjects[i].active !== this.projects[i].active) {
                    modifiedProjects.push(this.appliedProjects[i]);
                }
            }

            // Create an array of Promises for all the API requests
            const promises = modifiedProjects.map(project => {
            if (project.active) {
                return this.addToProject(project.team_id);
            } else {
                return this.removeFromProject(project.team_id);
            }
            });

            // Wait for all API requests to finish
            await Promise.all(promises);

        } catch (error) {
            console.error("Error:", error);
        } finally {
            await new Promise(r => setTimeout(r, 2000));
            this.$emit('projectsUpdate')
            this.projects = JSON.parse(JSON.stringify(this.appliedProjects))
            this.$emit('input', false);
            this.loading = false;
        }
        },

        getProjectsList(){
            this.loading = true;
            axios.get(`/organization/${this.org_id}/projects`).then(
                response => {
                    this.projectsList = response.data.data
                    this.loading = false;
                }
            )
        },

        onHidden() {
            this.loading = false;
            this.$emit('input', false);
            this.inputFields = [{ value: "", label: "Email address" }];
        },

        onHide(){
        },
    },
}
</script>
<style lang="scss">
@import url('../../styles/_colors.scss');

    .modal-600{
        max-width: 600px !important;
    }
    .assign-project{
        font-weight: 500;
        padding: 0.75rem;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        &:hover{
            background-color: #F6F5F5;
        }
    }
</style>
