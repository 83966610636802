<template>
    <b-modal class="modal-dialog-centered modal-lg" ref="myModal" v-model="modalShow" @hidden="onHidden" @hide="onHide" size="400" hide-footer hide-header>
    <div class="d-flex flex-column inviteUserModal" style="padding: 24px;">
        <div class="d-flex flex-row justify-content-between mb-4 align-items-start">                
            <BaseInviteIcon/>
            <button class="button-no-style" @click="modalShow=false"><BaseCloseIcon></BaseCloseIcon></button>
        </div>
        <div class="mb-2 supportingText">
            <span class="h4">{{$t('InviteUserModal.invite_collab_title')}}</span>
            <p class="mt-1">{{$t('InviteUserModal.invite_collab_description')}}</p>
        </div>
        <div v-if="!loading" class="d-flex flex-column mt-2" v-for="(input, index) in inputFields" :key="index">
            <label :for="index" style="">{{ input.label }}</label>
            <input :id="index" placeholder="Enter email" type="email" class="font-16 primary-border-button height-44 white-input primary-padding-input" v-model="input.value">
        </div>
        <button v-if="!loading" class="button-no-style mt-2 align-self-start color--magenta h6" @click="addInputField">+ {{$t('InviteUserModal.invite_collab_another')}}</button>
        <div v-if="loading" style="height: 100%; width: 100%; padding: 2rem;" class="d-flex align-items-center justify-content-center">
            <div class="spinner-border d-flex justify-content-center" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-center align-center mt-3" v-if="!loading">
            <button @click="modalShow=false" class="primary-border-button button-white height-44" style="width: 50%; margin-right: 6px; font-size: 16px;">{{$t('InviteUserModal.invite_collab_cancel')}}</button>
            <button class="button-accent500 primary-border-button height-44" style="width: 50%; margin-left: 6px; font-size: 16px;" @click="inviteUser">{{$t('InviteUserModal.invite_collab_invite')}}</button>
        </div>
    </div>
    </b-modal>
</template>

<script>
import BaseImportantIcon from "../base/BaseImportantIcon.vue";
import BaseInviteIcon  from "../base/BaseInviteIcon.vue"
import BaseCloseIcon from "../base/BaseCloseIcon.vue"
import Axios from "axios";
// import { response } from "express";

export default {
    components: {
        BaseImportantIcon,
        BaseInviteIcon,
        BaseCloseIcon
    },
    name: "InviteUserModal",
    props: {
        modalShow1: {
            type: Boolean,
            required: true
        },
        inputField: {
            type: String
        }
    },
    data() {
        return {
            modalShow: this.modalShow1,
            inputFields: [{ value: "", label: "Email address" }],
            loading: false,
            alert_error : "",
        };
    },
    watch: {
        modalShow1(newValue) {
            this.modalShow = newValue;
        }
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },

        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },

        onHidden() {
            this.loading = false;
            this.$emit('input', false);
            this.inputFields = [{ value: "", label: "Email address" }];
        },

        onHide(){
        },

        addInputField() {
            this.inputFields.push({ value: "", label: `Email address ${this.inputFields.length + 1}` });
        },

        removeInputField(index) {
            this.inputFields.splice(index, 1);
        },

        inviteUser(){
            this.loading = true
            let users = []
            this.inputFields.forEach(element => {
                if(element.value != ""){
                    const user = { email: element.value, role: "reviewer" };
                    users = users.concat(user)
                }
            });

            const obj = {
                "org_id": this.$store.getters.activeUser.org_id,
                "users": users
            }
            const res = Axios.put('/user', obj)
            .then(response => {
                this.loading = false
                this.$emit('users-invited')
                this.onHidden();
            }).catch(error => {
                console.log(error);
                this.onHidden();
                // alert("Error while sending email invite, try again later")
                this.showAlert()
            });
            console.log(res)
        }
    }
}
</script>

<style lang="scss">
.modal-400{
    width: 400px !important;
}
</style>