<template>
  <div>
    <div style="padding-left:50px;">
      <div style="padding:50px;">
        <div style="padding:50px; border:1px solid #DADADA; border-radius: 5px; text-align: center;">
          <h3>Topic Distribution</h3>
          <apexchart style="" width="100%" type="treemap" :series="seriesFromAIKNY" :options="treemapChartOptions"></apexchart>
          
      
        </div>
      </div>
      
      <!-- <div>
        <div style="padding:50px;">
          <div style="padding:50px; border:1px solid #DADADA; border-radius: 5px; border-color:#DADADA;text-align:center !important;">
            <h3>Associated vs Non Associated</h3>
              <div>
                <apexchart width="80%" height="" type="donut" :options="donutChartOptions" :series="seriesChartAssociated"></apexchart>
              </div>
          </div>
        </div>
      </div> -->
    </div>
    <div>
      <div style="padding:50px;">
        <div style="padding:50px; border:1px solid #DADADA; border-radius: 5px; border-color:#DADADA;text-align:center !important;">
          <h3>Sentiment</h3>
          <apexchart width="100%" type="bar" :options="barChartOptions" :series="sentimentSeries"></apexchart>
        </div>
      </div>
    </div>
    <button style="margin-left:150px" class="secondary" @click.prevent="computeAnalytics" >Calcola</button>
  </div>
</template>


<script>
import axios from "axios";
import _ from 'lodash';
export default {
  name: 'Analytics',
  props: { },
  data(){
    return{
      range:{
        start :  new Date(),
        end : new Date()
      },
      threshold :0.0,
      activeProject: localStorage.getItem("selectedProjectID"),
      donutChartOptions: {labels: ['Associated', 'Not Associates']},
      barChartOptions:{
        yaxis:{
          tickAmount: 6,
          logBase: 10,
          floating: false,
          min:0,
          max: 5,
          offsetX: 0,
          offsetY: 0
        },
        chart:{
          toolbar:{
            show: false
          }
        },
        plotOptions: {
          bar: {
            distributed: false
          },
          donut: {
            size: '65%',
            total: {
              show: false,
            }
          }
        },
        
      },
      seriesChartAssociated: [],
      treemapChartOptions: { // dummy data
            legend: {
              show: false
            },
            plotOptions: {
              treemap: {
                distributed: true
              }
            },

            chart: {
              height: 350,
              type: 'treemap',
              toolbar:{
                show: false
              }
            },
            title: {
              title: {
                align: 'center'
              }
            },
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '28px',
              },
              formatter: function(text, op) {
                return [text, op.value]
              },
              offsetY: -4
            },
          },
          
      seriesFromAIKNY:[{}],//array di oggetti [{x:chiave, y:valore}] GUARDA datafromAIKY
      sentimentSeries:[{}]
    }
  },
  watch:{
    range(){
      console.log("START: " + this.range.start.toISOString().slice(0, 19).replace('T', ' '))
      console.log("END: " + this.range.end.toISOString().slice(0, 19).replace('T', ' '))
    }
  },
  methods:{
    async getTreemapSeries(){
      const datafromAIKY =  //dummy data <- Qui chiamata ad AIKY... RITORNA UN ARRAY DI OGGETTI 
               await axios.post(`/project/${this.activeProject}/action/get-associated-topic-volumes` ,{
              threshold: this.threshold,
            })
      if(datafromAIKY.data.data.length === 0){return}
      console.log('datafromAIKY',datafromAIKY.data.data)
      var graphData =  _.map(datafromAIKY.data.data, function(obj){
          return {
            x: obj.topic_name,
            y: obj.conv_associated 
          }
      })  
      console.log('graphData',graphData)       
      this.seriesFromAIKNY = [{
        data: graphData
      }]
    },
     async getSentimentSeries(){
      const datafromAIKY =  //dummy data <- Qui chiamata ad AIKY... RITORNA UN ARRAY DI OGGETTI 
               await axios.post(`/project/${this.activeProject}/action/get-avg-sentiment-per-topic` ,{
              threshold: this.threshold,
            })
      if(datafromAIKY.length === 0){return}      
      console.log('datafromAIKY',datafromAIKY.data.data)
      var graphData =  _.map(datafromAIKY.data.data, function(obj){
          return {
            x: obj.topic_name,
            y: obj.avg_sentiment.toFixed(2) 
          }
      })  
      console.log('graphData',graphData)       
      this.sentimentSeries = [{
        data: graphData
      }]
    }, 
    async getPieVolumes(){
      const datafromAIKY = 
        await axios.post(`/project/${this.activeProject}/action/get-conversations-associated-ratio`)
        if(datafromAIKY.length === 0){return}
      this.seriesChartAssociated = [datafromAIKY.data.data.associated,datafromAIKY.data.data.not_associated]
    },
    async computeAnalytics(){
      await axios.post(`/project/${this.activeProject}/action/compute-agent-analytics`)
    }
  },
 
  beforeMount(){
    this.getTreemapSeries(),
    this.getSentimentSeries(),
    this.getPieVolumes()
  }
  
  
}
</script>