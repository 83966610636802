import Vue from "vue";
import App from "./App.vue";
import store from "./store";

import {
  BootstrapVue,
  NavbarPlugin,
  BIcon,
  BIconChevronRight,
} from "bootstrap-vue"; // import selected BootstrapVue plugins

import router from "@/router";
import VueMoment from "vue-moment";
import ApiService from "@/common/api.service";
import VueApexCharts from 'vue-apexcharts'
import VCalendar from 'v-calendar';

import {
  domain,
  clientId,
  audience
} from "../auth_config.json";

import directives from "./directives";

Vue.config.productionTip = false;

/**
 * Init The API Service
 *
 * Set the auth0 authorization header for each API request
 */
ApiService.init();

/**
 * Install ApexChart
 */
 Vue.use(VueApexCharts)
 Vue.component('apexchart', VueApexCharts)

/**
 * Install BootstrapVue
 */
Vue.use(BootstrapVue); // Here we can import only the components we are using
Vue.component("BIcon", BIcon);
Vue.component("BIconChevronRight", BIconChevronRight);

/**
 * Install selected BootstrapVue plugins.
 */
Vue.use(NavbarPlugin);

/**
 * Date formatter
 */
Vue.use(VueMoment);

/**
 * Datepicker
 */
Vue.use(VCalendar)
Vue.component("v-calendar", VCalendar);


// Vue.filter('formatDate', function (value, format) {
//   if (value) {
//     var _format = (format ? format : 'dd.MM.yyyy');
//     return moment(String(value)).format(_format);
//   }
// });

// router.beforeEach((to, from, next) => {
//   Promise.all([store.dispatch("checkTenant")]).then(next);
// });

/**
 * Install Vue I18n.
 */
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: navigator.language // use the user browser´s language
});

const setLocaleMessages = async () => {
  const language = navigator.language.split("-")[0];
  const messages = await import(`@/locale/${language}.json`);
  i18n.setLocaleMessage(language, messages[language]);
};
setLocaleMessages();

/* Fetching and committing the activeUser from session after each reload or initialization */
const activeUser = JSON.parse(sessionStorage.getItem('activeUser'));
if (activeUser) {
  store.commit('setUser', activeUser);
}

new Vue({
  directives,
  router,
  store,
  i18n,
  data: {
    darkMode: false,
  },
  render: function (h) {
    return h(App);
  }
}).$mount("#app");