export const API_URL = process.env.VUE_APP_API_URL;
export const API_VERSION = "v1";
export const RES_TENANT = "tenant";
export const RES_TENANTS = "tenants";

export const RES_USER = "user";
export const RES_USERS = "users";

export const RES_TEAM = "team";
export const RES_TEAMS = "teams";

export const RES_TEAM_ACT_ADD_USERS = "action/add-users";
export const RES_TEAM_ACT_DELETE_USERS = "action/remove-users";