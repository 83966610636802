<template lang="">
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" style="" class="inviteIcon_animation">
        <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2"/>
        <path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8"/>
    </svg>
</template>

<script>
export default {
    name: "BaseImportantIcon"
}
</script>
<style lang="scss">
    .inviteIcon_animation{
        animation: inviteIcon_pulse 1.5s;
        animation-iteration-count: infinite;
    }

    @keyframes inviteIcon_pulse {
        0% { stroke: #ffedf575;}
        50% {stroke: #FFEDF5;}
        100% { stroke: #ffedf571;}
    }
</style>