<template lang="">
    <svg class="close_icon" width="16" height="16" viewBox="0 0 14 14"  xmlns="http://www.w3.org/2000/svg">
    <path d="M8.40994 7.00019L12.7099 2.71019C13.1021 2.31806 13.1021 1.68231 12.7099 1.29019C12.3178 0.898063 11.6821 0.898063 11.2899 1.29019L6.99994 5.59018L2.70994 1.29019C2.31782 0.898063 1.68206 0.898063 1.28994 1.29019C0.897817 1.68231 0.897817 2.31806 1.28994 2.71019L5.58994 7.00019L1.28994 11.2902C1.10063 11.478 0.994141 11.7335 0.994141 12.0002C0.994141 12.2668 1.10063 12.5224 1.28994 12.7102C1.47771 12.8995 1.7333 13.006 1.99994 13.006C2.26658 13.006 2.52217 12.8995 2.70994 12.7102L6.99994 8.41018L11.2899 12.7102C11.4777 12.8995 11.7333 13.006 11.9999 13.006C12.2666 13.006 12.5222 12.8995 12.7099 12.7102C12.8993 12.5224 13.0057 12.2668 13.0057 12.0002C13.0057 11.7335 12.8993 11.478 12.7099 11.2902L8.40994 7.00019Z"/>
    </svg>
</template>
<script>
export default {
    
}
</script>
<style lang="scss">

    .close_icon{
        path{
            fill: black;
            transition: all 0.25s ease-in-out;
        }
    }
    .close_icon:hover{
        path{
            transition: all 0.25s ease-in-out;
            fill: rgb(122, 122, 122);
        }
    }
</style>