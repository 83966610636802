<template>
  <b-alert class="baseToast" :show="dismissCountDown"  variant="light" @dismissed="dismissCountDown=0">
    <div class="baseToast__body">
      <div class="baseToast__body__icon">
        <img v-if="!isSuccess" src="../../assets/notification_banner_icon_1.svg" />
        <img v-else src="../../assets/notification_banner_icon_2.svg" />
      </div>
      <div class="baseToast__body__description">
        <span>{{ heading }}</span>
        <p>{{ message }}</p>
      </div>
    </div>
    <div v-if="accept && dismiss" class="baseToast__footer">
      <p @click="acceptClicked">{{ !isSuccess ? 'View errors' : accept ? accept : '' }}</p>
      <p @click="dismissClicked">{{ dismiss ? dismiss : '' }}</p>
    </div>
  </b-alert>
</template>

<script>
export default {
  name: "Alert",
  props: {
    heading: { default: "", type: String },
    message: { default: "-", type: String, required: true },
    autoCloseDelay: { default: 3, type: Number },
    dismiss: { default: "", type: String },
    accept: { default: "", type: String }
  },
  data() {
    return {
      dismissCountDown: 0,
      isSuccess:true
    };
  },
  methods: {
    /**
     * @vuese
     * Used to show the alert
     */
    showAlert(isSuccess) {
      this.isSuccess = isSuccess;
      this.dismissCountDown = this.autoCloseDelay;
    },

    acceptClicked: function() {
      this.$emit("accept-clicked");
    },

    dismissClicked: function() {
      this.$emit("dismiss-clicked");
    }
  }
};
</script>