import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";

Vue.use(Router);

let localRoutes;

try {
  localRoutes = require("./localRoutes").localRoutes;
} catch (e) {
  localRoutes = [];
}

const definitiveRoutes = routes.concat(localRoutes);

export default new Router({
  mode: "history",
  routes: definitiveRoutes
});
