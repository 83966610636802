<template>
  <div class="adminSidebar" style="position:fixed">
    <b-nav  class="adminSidebar_list_item">
      <div style="margin-top:12px">
        <li class="adminSidebar_item" style="margin-bottom:45px">
          <i class="adminSidebar__list__item__logo"></i>
        </li>
        <li class="adminSidebar_item align-middle">
          <router-link to="/admin" exact-active-class="adminSidebar__list__item__active" class="adminSidebar__list__item topic" title="Topics">
            <!-- <i title="Ciao" class="adminSidebar__list__item bot-analytics"></i> -->
          </router-link>
        </li>
        <li class="adminSidebar_item align-middle">
          <router-link to="/analytics-agents" exact-active-class="adminSidebar__list__item__active" class="adminSidebar__list__item agent-analytics" title="Agent Analytics">
            <!-- <i title="Ciao" class="adminSidebar__list__item bot-analytics"></i> -->
          </router-link>
        </li>
        <li class="adminSidebar_item align-middle">
          <router-link to="/analytics-bot" exact-active-class="adminSidebar__list__item__active" class="adminSidebar__list__item bot-analytics" title="Bot Analytics">
            <!-- <i title="Ciao" class="adminSidebar__list__item bot-analytics"></i> -->
          </router-link>
        </li>
        <li class="adminSidebar_item align-middle">
          <router-link to="/project-settings" exact-active-class="adminSidebar__list__item__active" class="adminSidebar__list__item settings-icon" title="Settings">
            <!-- <i title="Ciao" class="adminSidebar__list__item bot-analytics"></i> -->
          </router-link>
        </li> 
      </div>
      <div>
        <li class="adminSidebar_item align-middle">
          <router-link to="/projects" exact-active-class="adminSidebar__list__item__active" class="adminSidebar__list__item workspaceIcon" title="Projects list">
            <!-- <i title="Ciao" class="adminSidebar__list__item bot-analytics"></i> -->
          </router-link>
        </li> 
        <li class="adminSidebar_item">
          <button class="adminSidebar__list__item__button" @click.prevent="$emit('logout')" href="#" title="Log out"><i class="adminSidebar__list__item userIcon"></i></button>
        </li>
      </div>
    </b-nav>
  </div>
</template>

<script>
import bus from "@/bus";
import axios from "axios";

export default {
  name: "AdminSidebar",
  props: {
    active: { default: Object, type: Object },
  },
  data() {
    return {
      sidebarResized: false,
      isEdited: false
    }
  },
  created() {
  },
  methods:{
    openModal(modal) {
      this.$bvModal.show(modal);
    },
    resizeSidebar(){
      this.sidebarResized = !this.sidebarResized
    },
    goToTopicPage: function(){
      this.$store.commit("setTheDefaultView", "topicsView");
      this.$router.push('/admin')
    }
  }
};
</script>