<template>
<div>
  <b-container>
    <b-row>

      <b-col></b-col>
      
      <b-col class="theLoginForm" >
    
        <!-- Alert for user password being reset. -->
        <Alert
          :heading="$t('password.reset.label')"
          :message="$t('password.reset.confirm')"
          ref="alert"
        />
        <div class="theLoginForm__heading"></div>
        <!-- Show the `login` view. -->
        <div v-if="loginView === 'login'">
          <p class="theLoginForm__subheading--is-login">{{ $t("login.label") }}</p>
          <b-form action="/custom-login" class="theLoginForm__form" id="login" method="post" @submit.stop.prevent="handleLogin">
              <input autocomplete="username" class="theLoginForm__form__email" id="email" name="email" :placeholder="$t('email.address')" required type="email" v-model="form.email"/> 
              <div class="theLoginForm__form__password">
                <input class="theLoginForm__form__password--input" autocomplete="current-password"  id="password" name="password" :placeholder="$t('password.label')" required :type="fieldType" v-model="form.password"/>
                <button class="theLoginForm__form__showPasswordButton" @click="showPassword" type="button"><i :class="`theLoginForm__form${fieldType=='password' ? '__showPasswordIcon' : '__hidePasswordIcon' }`"></i></button>
              </div>
            <div v-if="form.warning" class="theLoginForm__warning">
              <p class="color--magenta dig-font-weight-bold">{{ $t("login.warning") }}</p>
            </div>
            <div class="theLoginForm__form__options"> 
              <button  class="theLoginForm__form__forgotPassword" @click="switchLoginView" role="button" type="button" variant="link">{{ $t("password.forget.label") }}</button>
              <button class="primary" type="submit">{{ $t("login.button") }}</button>
            </div>
          </b-form>
          <div class="theLoginForm__azure">
            <p>{{ $t("login.azure_label") }}</p>
            <a class="secondary nestedIcon" href="auth/azure-login"><i class="theLoginForm__azureIcon"></i> {{ $t("login.azure") }}</a>
          </div>
        </div>
        <!-- Show the `forgotPassword` view. -->
        <div v-else-if="loginView === 'forgotPassword'">
          <b-form class="theLoginForm__form" id="reset-password" @submit.stop.prevent="handleResetPassword">
            <p class="theLoginForm__subheading--is-forgot-password dig-font-weight-bold">{{ $t("password.reset.personal") }}</p>
            <p class="theLoginForm__description">{{ $t("password.reset.message") }}.</p>
              <b-form-group id="input-group-1">
                <b-form-input class="theLoginForm__form__email" id="email" name="email" :placeholder="$t('email.address')" required type="email" v-model="form.email"></b-form-input>
              </b-form-group>
            <b-button class="primary" @click="sendEmailForgotPsw" type="submit">{{ $t("send.email") }}</b-button>
            <div class="displayFlexAndCenter">
              <i class="theLoginForm__arrow"></i>
              <button class="theLoginForm__form__backToDefault" @click="switchLoginView" variant="link">{{ $t("back") }}</button>
            </div>
          </b-form>
        </div>
        <!-- Show the `login` view. -->

        <div v-else-if="loginView === 'checkYourEmail'">
          <p class="theLoginForm__subheading--is-forgot-password dig-font-weight-bold">{{ $t("password.forget.checkemail") }}</p>
          <p class="theLoginForm__description">{{ $t("password.forget.instructions") }}  <br> {{form.email}}</p>
          
          <a class="theLoginForm__openEmail primary" href="mailto:" type="submit">{{ $t("password.forget.openmailapp") }}</a>
          
          <p class="theLoginForm__description">{{ $t("password.forget.dontreceive") }}<a href="">Click to resend</a></p>

          <div class="displayFlexAndCenter">
              <i class="theLoginForm__arrow"></i>
              <button class="theLoginForm__form__backToDefault" @click="switchLoginView" variant="link">{{ $t("back") }}</button>
          </div>
        </div>

      </b-col>

      <b-col></b-col>

    </b-row>
  </b-container>
</div>
  
</template>

<script>
import { Alert } from "@/components";
import axios from "axios"; // TODO: refactor needed
import cookies from "@/common/cookies"; // TODO: refactor needed
import { API_URL} from "@/common/config";

export default {
  name: "TheLoginForm",
  components: {
    Alert
  },
  data() {
    return {
      form: {
        checked: false,
        email: "",
        password: "",
        warning: false,
        api_url: API_URL,
      },
      loginView: "login",
      showDismissibleAlert: false,
      fieldType: "password"
    };
  },
  methods: {
    /** 
     * Switch between the two available views.
     */
    switchLoginView() {
      this.loginView = this.loginView === "login" ? "forgotPassword" : "login";
      this.form.checked = false;
      this.form.email = "";
      this.form.password = "";
      this.form.warning = false;
    },

    async sendEmailForgotPsw(){
      try {
        await axios.post('/user/send-reset-password-email', { email: this.form.email }).then(res => {
          console.log(res)
        });
      }
      catch {}
      
    },


    showPassword(){
      if (this.fieldType == "password") {
        this.fieldType = "text";
      } else this.fieldType = "password";
    },

    /**
     * Handle the user password reset via e-mail.
     */
    handleResetPassword() {
      // TODO: link to the backend reset password logic as soon as available
      this.loginView = "checkYourEmail";
    },

    async handleLoginAzureAD() {
      console.log("handleLoginAzureAD")
      // TODO: refactor needed
      try {
        const headers = {
          "Access-Control-Allow-Origin": "*"
        };
        const res = await axios.get("/auth/azure-login", headers);
        if (res.status === 200) {
          const id = res.data.data.id;
          const token = res.data.data.jwt;
          setUser(id)
          /**
           * Save the user token in either a `localStorage` or a `sessionStorage` object.
           */
          if (localStorage || sessionStorage) {
            this.form.checked ? localStorage.setItem("id", id) : sessionStorage.setItem("id", id);
            this.form.checked ? localStorage.setItem("jwt", token) : sessionStorage.setItem("jwt", token);
          } else {
            /**
             * Save the user token in a cookie, as a fallback.
             */
            if (this.form.checked) {
              cookies.setCookie("id", id);
              cookies.setCookie("jwt", token);
            }
          }
          this.$store.commit("setAuth", true);
          this.$router.push("/projects");
          /**
           * Save given e-mail address and password in a `CredentialsContainer` (e.g., “Google Password Manager”), if available.
           */
          if (window.PasswordCredential) {
            const passwordCredential = new PasswordCredential({ id: this.form.email, password: this.form.password });
            navigator.credentials.store(passwordCredential);
          } else {
            return Promise.resolve();
          }
        }
      } catch {
        this.form.warning = true;
      }
    },
    
    /**
     * Handle the user login with e-mail and password.
     */
    async handleLogin() {
      console.log("handlelogin")
      // TODO: refactor needed
      try {
        const res = await axios.post("/custom-login", { email: this.form.email, password: this.form.password }, { withCredentials: true });
        if (res.status === 200) {
          console.log("STATUS 200 LOGIN")
          const id = res.data.data.id;
          const token = res.data.data.jwt;
          /**
           * Save the user token in either a `localStorage` or a `sessionStorage` object.
           */
          if (localStorage || sessionStorage) {
            this.form.checked ? localStorage.setItem("id", id) : sessionStorage.setItem("id", id);
            this.form.checked ? localStorage.setItem("jwt", token) : sessionStorage.setItem("jwt", token);
          } else {
            /**
             * Save the user token in a cookie, as a fallback.
             */
            if (this.form.checked) {
              cookies.setCookie("id", id);
              cookies.setCookie("jwt", token);
            }
          }
          this.$store.commit("setAuth", true);
          this.setUser(id)
          this.$router.push("/projects");
          /**
           * Save given e-mail address and password in a `CredentialsContainer` (e.g., “Google Password Manager”), if available.
           */
          if (window.PasswordCredential) {
            const passwordCredential = new PasswordCredential({ id: this.form.email, password: this.form.password });
            navigator.credentials.store(passwordCredential);
          } else {
            return Promise.resolve();
          }
        }
      } catch {
        this.form.warning = true;
      }
    },
    setUser(id) {
      console.log("setUser");
      const token = localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
      axios.get(`/user/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          sessionStorage.setItem("activeUser", JSON.stringify(response.data.data));
          this.$store.dispatch("setUser", response.data.data);
      })
        .catch(error => {
          console.error("Error in setUser:", error);
      });
    }
    
  }
};
</script>