<template>
  <div>
    <b-spinner v-if= "start" label="Loading..."></b-spinner>
  </div>
</template>

<script>
  export default {
    name: "Spinner",
    props:{
         start: { default: false, type: Boolean },
    }
  }
</script>

<style>

</style>