<template>
  <div>

    <div v-if="spinner" class="spinner" >
      <img class="spinner--view" src="../../assets/aiku_spinner-2.gif" />
    </div>
    <div v-show="!spinner">
      <!-- Show the 'topicsView' view. -->
      <div class="adminTopicModelingDashboard" :style="$store.state.salesforce ? 'width:100%' : ''" v-if="$store.state.theDefaultView === 'topicsView'" >
        
        <span class="adminTopicModelingDashboard__title">Topic Modeling</span>
        <!-- <div class="adminTopicModelingDashboard__details" :style="$store.state.salesforce ? 'width:100%' : ''"> SALESFORCE????-->

          <div class="adminTopicModelingDashboard__details__left">
            <span class="adminTopicModelingDashboard__details__name">{{ active.name }}</span>
          </div>

          <div class="adminTopicModelingDashboard__ingestion">
            <div class="adminTopicModelingDashboard__ingestion--box">
              <div class="adminTopicModelingDashboard__ingestion--label_box">
                <span class="dig-font-weight-bold">{{clusterizedConversations}}/{{totalConversations}}</span>
                <span>{{ $t("admin.ingestion.label") }}</span>
              </div>
              <div class="marginAuto"><button :disabled="clusterizedConversationEqualToTotal" :class=" clusterizerSpin ? 'empty' : 'secondary'" @click="clusterize"><Spinner class="adminTopicModelingDashboard__spinner" :start = clusterizerSpin />{{ clusterizerSpin ? '' : $t("admin.ingestion.button") }}</button></div>
            </div>
            <div class="adminTopicModelingDashboard__ingestion--box">
              <div class="adminTopicModelingDashboard__ingestion--label_box">
                <span class="dig-font-weight-bold">{{ $t("admin.ingestion.upfilelabel") }}</span>
                <span>.csv</span>
              </div>
              <div class="marginAuto"><button :class=" fileuploadSpin ? 'empty' : 'secondary'" @click="openModal('upload-file')"><Spinner class="adminTopicModelingDashboard__spinner" :start = fileuploadSpin />{{ fileuploadSpin ? '' : $t("admin.ingestion.upfilebutton") }}</button></div>
            </div>
            <div class="adminTopicModelingDashboard__ingestion--box">
              <div class="adminTopicModelingDashboard__ingestion--label_box">
                <span class="dig-font-weight-bold">{{ $t("training.title") }}</span>
                <span class="adminTopicModelingDashboard__ingestion--span">{{ $t("training.description") }}</span>
              </div>
              <div class="marginAuto"><button :class=" trainSpin ? 'empty' : 'secondary'" @click.prevent="trainClassifier()"><Spinner class="adminTopicModelingDashboard__spinner" :start = trainSpin />{{ trainSpin ? '' : $t("admin.projects.topics.train") }}</button></div>
            </div>
          </div>
          
          <Alert
          :message="$t(alertMessage)"
          ref="alert"
          />
        <!-- </div> -->
        
        <!-- Import 'topicModelingTable' component with parameters. -->
        <div>
          <TopicModelingTable
            :active="id"
            :files="files"
            :startClustersQuality="startClustersQuality"
            @list-qas="getQasList"
            @spinner="setFalseSpinner"
            @train="trainClassifier"
            @setToFalseComputeClusterQuality="setToFalseComputeClusterQuality"
          />
          <!-- Modal to upload a new file. -->
          <AdminModal
            @cancel-action="closeModal"
            @confirm-action="uploadFile"
            id="upload-file"
            title="admin.modals.upload.file.title"
            :uploadFile="true"
          />
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios"; // TODO: refactor needed
import bus from "@/bus";
import icons from "@/styles/_icons.scss";
import utils from "@/common/utils";

import { AdminModal, TopicModelingTable, Alert, Spinner } from "@/components";

export default {
  name: "AdminTopicModelingDashboard",
  components: {
    AdminModal,
    TopicModelingTable,
    Alert,
    Spinner
  },
  props: {
    active: { default: Object, type: Object, required: true }
  },
  data() {
    return {
      activeProjectId: Number(localStorage.getItem("selectedProjectID")),
      activeTopicId: null,
      activeTopicTitle: "",
      answerItem: "answer",
      alertMessage: "",
      answersCount: 0,
      clusterizedConversations: 0,
      clusterizedConversationEqualToTotal: false,
      spinner: true,
      files: [],
      id: Number(localStorage.getItem("selectedProjectID")),
      questionItem: "question",
      questionsAndAnswers: {},
      questions: {},
      answers: {},
      answersCount: 0,
      questionsCount: 0,
      topics: [],
      totalConversations: 0,
      visible_1: true,
      visible_2: true,
      fileuploadSpin: false,
      trainSpin: false,
      trainStatus: "NONE",
      clusterizerSpin : false,
      clusterizerStatus: "NONE",
      fileUploadStatus : "DONE",
      startClustersQuality:false
    };
  },
  created() {
    this.authorizeUser(); // TODO: refactor needed
  },
  methods: {

    setToFalseComputeClusterQuality(){
      this.startClustersQuality = false;
    },

    async checkFileUploadStatus(counter){
      try{
        const res = await axios.get(`/project/${this.activeProjectId}/files`)
        if (res.status === 200 && counter < 60 && res.data.data.length > 0){
          let file = _.maxBy(res.data.data, 'id')
          this.fileUploadStatus = file.conv_upload_status
          if(file.conv_upload_status == 'DONE'){
            return
          }else if (file.conv_upload_status == "FAILED") {
            this.alertMessage= "admin.modals.upload.file.error";
            this.$refs.alert.showAlert();
            return      
          }else{
            setTimeout(() => { this.checkFileUploadStatus(counter+1); }, 10000);
          }
        }
      }
      catch(error){
        console.log(error);
      }
    },

    /**
     * Upload a new file, then check the status of adding the file.
     * @param {object} file - File to be updated.
    */
    async uploadFile(file) {
      this.fileuploadSpin = true;
      let formData = new FormData();
      formData.append("file", file);
      // this.alertMessage= "admin.modals.upload.file.start";
      // this.$refs.alert.showAlert();
      await axios
              .post(`/project/${this.activeProjectId}/action/ingest-conversations-csv`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }).then(() => {
                  this.fileuploadSpin = false;
                  this.alertMessage= "admin.modals.upload.file.start";
                  this.$refs.alert.showAlert();
                  this.updateClusterizedConversationsInfo(this.activeProjectId)
                  this.checkFileUploadStatus(0);
                });
    },

    async clusterize(){
      this.clusterizerSpin = true
        await axios
              .post(`project/${this.activeProjectId}/action/clusterize-conversations`)
                .then(() => {
                  // this.$emit("list-files", this.activeProjectId);
                  this.alertMessage= "admin.ingestion.start_clusterization";
                  this.$refs.alert.showAlert();
                  setTimeout(() => { this.checkClusterizationStatus(0); }, 5000);
                });

    },

    async checkClusterizationStatus(counter){
      try {
        if((counter < 60)){
          const res = await axios.get(`project/${this.activeProjectId}/action/check-clusterization-status`);
          this.clusterizerStatus = res.data.data.status;
          if (res.status === 200 && res.data.data.status === "DONE"){
            if(counter > 0){
              this.alertMessage= "admin.ingestion.success_clusterization";
              this.$refs.alert.showAlert();
            }
            this.getActiveProjectTopics(this.activeProjectId)
            this.updateClusterizedConversationsInfo(this.activeProjectId)
            this.clusterizerSpin = false;
          }else if (res.status === 200 && res.data.data.status === "FAILED") {
            this.alertMessage= "admin.ingestion.fail_clusterization";
            this.$refs.alert.showAlert(false);
            this.getActiveProjectTopics(this.activeProjectId)
            this.updateClusterizedConversationsInfo(this.activeProjectId)
            this.clusterizerSpin = false;
          }else{
            setTimeout(() => { this.checkClusterizationStatus(counter+1); }, 20000);
          }
        }else{
          //TODO show error alert
          this.clusterizerSpin = false;
        }
      }
      catch(error){
        console.log(error);
      }
    },
    /*
     * COMPLEMENTARY METHOD OF trainClassifier()
    */
    async checkTrainingStatus(counter){
      try {
        if((counter < 60)){
          const res = await axios.get(`project/${this.activeProjectId}/action/check-training`);
          this.trainStatus = res.data.data.status
          if (res.status === 200 && res.data.data.status === "TRAINED"){
            if(counter > 0){
              this.alertMessage= "training.end_training_message";
              this.$refs.alert.showAlert();
            }
            this.trainSpin = false
            
          }else if (res.status === 200 && res.data.data.status === "FAILED") {
            this.alertMessage= "training.error_training_message";
            this.$refs.alert.showAlert(false);
            this.trainSpin = false
          }else{
            setTimeout(() => { this.checkTrainingStatus(counter+1); }, 15000);
          }
        }else{
          //TODO show error alert
        }
      }
      catch(error){
        console.log(error);
      }
    },

    /*
     * THIS METHOD WILL LANCH THE TRAINING PROCESS AND THEN CHECK FOR A MAXIMUM OF 60 SECONDS
    */
    async trainClassifier(){
      try {
        const res = await axios.post(`project/${this.activeProjectId}/action/train-classifier`);
        if (res.status === 200) {
          this.trainSpin = true
          this.alertMessage= "training.request_training_message"
          this.$refs.alert.showAlert()
          setTimeout(() => { this.checkTrainingStatus(0); }, 5000);
          this.startClustersQuality = true
        } else {
          //TODO show error alert
        }
      }
      catch(error){
        console.log(error);
      }
    },    

    /**
     * Close the selected modal.
     @param {string} modal - ID of the selected modal.
     */
    // closeModal(modal) {
    //   let form = document.querySelector(`#${modal} form`);
    //   form.reset();
    //   this.$bvModal.hide(modal);
    //   if (modal === "create-project" && this.adminView === "noProjects") {
    //     this.welcomeMessage("show");
    //   }
    // },
    
    /**
     * Close the selected modal.
     * @param {string} modal - ID of the selected modal.
     */
    closeModal(modal) {
      this.$bvModal.hide(modal);
    },
    /**
     * Open the selected modal.
     * @param {string} modal - ID of the selected modal.
     */
    openModal(modal) {
      this.$bvModal.show(modal);
    },
    /**
     * Emit a custom `save` global event to save the local changes to the database.
     * @param {array} items - Array of edited questions and/or answers.
     */
    saveChange(item) {
      bus.$emit("edited", item);
    },

    // TODO: refactor needed

    /**
     * Authorize the user by either `window.localStorage', `window.sessionStorage`, or a cookie-stored token as a fallback.
     */
    authorizeUser() {
      let token;
      if (localStorage || sessionStorage) {
        token = localStorage.getItem("jwt")
          ? localStorage.getItem("jwt")
          : sessionStorage.getItem("jwt");
      } else {
        token = cookies.getCookie("jwt");
      }
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
       /**
     * Update clusterized conversations info.
     * @param {string} project_id - ID of the active project.
     */
    async updateClusterizedConversationsInfo(id) {
      await axios.get(`/project/${id}/action/get-conversations-clusterized-info`).then((res) => {
        this.clusterizedConversations = res.data.data.clusterized_conversations;
        this.totalConversations = res.data.data.total_conversations;
        /* the clusterized button is disable if the conversazion delta is less than 1000 */
        this.clusterizedConversationEqualToTotal = ((this.totalConversations - this.clusterizedConversations) < 1000) ?  true : false;
      });
    },

    setFalseSpinner(){
      this.spinner = false;
      console.log(this.spinner )
    },
    /**
     * Get the topics list of the active project.
     * @param {string} id - ID of the active project.
     */
    async getActiveProjectTopics(id) {
      await axios.get(`/project/${id}/topics`).then((res) => {
        this.topics = res.data.data.topics;
        this.defaultView = "topicsView";
      });
    },
    /**
     * Get the uploaded files list of the active project, then show it.
     * @param {number} id - ID of the active project.
     */
    async getFilesList(id) {
      await axios.get(`/project/${id}/files`).then((res) => {
        this.files = res.data.data;
      });
    },
    /**
     * Get the questions and answers list of the active topic, then show it.
     * @param {object} topic - Object of the active topic.
     */
    async getQasList(topic) {
      this.$router.push(`/qa/${topic.id}`)
    },
    /**
     * Delete the active topic.
     * @param {number} id - ID of the active topic.
     */
    async deleteTopic(id) {
      await axios.delete(`/topic/${id}`).then(() => {
        this.getActiveProjectTopics(this.id);
        this.closeModal("delete-topic");
        this.$store.commit("setNavbar", {
          generateBot: true,
          isUserLoggedIn: true,
          theActiveProjectTitle: false,
        });
        this.$store.commit("setTheDefaultView", "topicsView");
      });
    },

  },
  beforeMount() {
    /*this.getFilesList(this.id);*/
    this.getActiveProjectTopics(this.id);
    this.updateClusterizedConversationsInfo(this.id);
    this.checkFileUploadStatus(0);
    this.checkTrainingStatus(0);
    this.checkClusterizationStatus(0);
  },
  mounted() {
    /**
     * Reset the active modals inputs.
     * @event bvModalEvt
     * @param {string} modalId - ID of the selected modal.
     */
    this.$root.$on("bv::modal::hide", (bvModalEvt, modalId) => {
      if (this.$refs.modal_3) {
        this.$refs.modal_3.form = {};
      }
    });
    if(this.$store.state.isEditedItems){
      // this.trainClassifier();
    }
  },
  updated() {
    this.id = Number(localStorage.getItem("selectedProjectID"));
  },
  watch: {
    fileUploadStatus(value) {
      let yes_states = ["DONE","FAILED"]
      let running_states = ["ANONYMIZING", "STORING", "STARTED"]
      if(yes_states.includes(value)) {
        this.fileuploadSpin = false
        this.updateClusterizedConversationsInfo(this.id);
      }else if(running_states.includes(value)){
        this.fileuploadSpin = true
      }
    },
    trainStatus(value) {
      let yes_states = ["DONE","FAILED","NONE"]
      let running_states = ["IN_TRAINING"]
      if(yes_states.includes(value)) {
        this.trainSpin = false
      }else if(running_states.includes(value)){
        this.trainSpin = true
      }
    },
    clusterizerStatus(value) {
      let yes_states = ["DONE","FAILED","NONE"]
      let running_states = ["ON_GOING"]
      if(yes_states.includes(value)) {
        this.clusterizerSpin = false
      }else if(running_states.includes(value)){
        this.clusterizerSpin = true
      }
    }
  },
};
</script>