<template>
  <div id="app">
    <AdminSidebar v-if="!['login', 'projects', 'oauth', 'resetPsw', 'manage-users', 'manage-organizations'].includes($route.name) && !$route.path.includes('/demo')"
    @logout="handleLogout"
    ref="sidebar"/>
    <router-view />
  </div>
</template>

<script>
import {AdminSidebar} from '@/components';

export default {
  name: "App",
    components: {
      AdminSidebar
  },
  created() {
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    handleLogout() {
      if (localStorage || sessionStorage) {
        localStorage.removeItem("jwt") || sessionStorage.removeItem("jwt");
      } else {
        this.cookies.deleteCookie("jwt");
      }
      this.$store.commit("setAuth", false);
      this.$router.push("/login");
    },
  }
};
</script>

<style lang="scss">
@import "@/styles/main.scss";
</style>
