<template>
    <div>
        <InviteUserModal :modalShow1="modals.Invite" v-model="modals.Invite" :inputField="inputField" @users-invited="UsersDataUpdate"></InviteUserModal>
        <ConfirmModal :modalShow1="modals.ConfirmDelete" v-model="modals.ConfirmDelete" @confirmed="deleteUser(selected.userToDelete)"/>
        <ConfirmModal :modalShow1="modals.ConfirmMultiDelete" v-model="modals.ConfirmMultiDelete" @confirmed="multiDisable()"/>
        <AssignProjectModal @projectsUpdate="modals.Assign" :modalShow1="modals.Assign" v-model="modals.Assign" :activeUser="activeProjectUser" :org_id="activeUser.org_id"></AssignProjectModal>
        <BaseActionBar :active="DisplayActionBar">
            <template v-slot:content>
                <button @click="toggleAll(false)" class="button-no-style text-light">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 1L1 11M1 1L11 11" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <span class="ml-5">{{selected.length}} {{$t('projects.users_table.activityBar.selected_users')}}</span>
                <button class="ml-5 d-flex flex-column justify-content-center align-items-center button-no-style BaseActionBar__button text-light" @click="modals.ConfirmMultiDelete=true">
                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.60768 4.10768L15.391 15.891M17.8327 9.99935C17.8327 14.6017 14.1017 18.3327 9.49935 18.3327C4.89698 18.3327 1.16602 14.6017 1.16602 9.99935C1.16602 5.39698 4.89698 1.66602 9.49935 1.66602C14.1017 1.66602 17.8327 5.39698 17.8327 9.99935Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="mt-1">{{$t('projects.users_table.activityBar.disable')}}</span>
                </button>
                <b-dropdown id="dropdown-buttons" toggle-class="ml-5 d-flex flex-column justify-content-center align-items-center button-no-style BaseActionBar__button text-light" no-caret>
                    <template #button-content>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1168_54170)">
                                <path d="M9.99984 13.3346L13.3332 10.0013M13.3332 10.0013L9.99984 6.66797M13.3332 10.0013H6.6665M18.3332 10.0013C18.3332 14.6037 14.6022 18.3346 9.99984 18.3346C5.39746 18.3346 1.6665 14.6037 1.6665 10.0013C1.6665 5.39893 5.39746 1.66797 9.99984 1.66797C14.6022 1.66797 18.3332 5.39893 18.3332 10.0013Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1168_54170">
                                    <rect width="20" height="20" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <span class="mt-1">{{$t('projects.users_table.activityBar.assign_role')}}</span>  
                    </template>
                    <b-dropdown-item-button v-for="role in roles" @click="RoleRequest(selected, role.id)">{{role.name}}</b-dropdown-item-button>
                </b-dropdown>
            </template>
        </BaseActionBar>
        <div style="overflow-x: auto; margin-top: 80px;">
        <div class="manage-users__container" style="width: fit-content; margin: auto; overflow-x: auto;">
            <div class="d-flex justify-content-between" style="margin-bottom: 32px;">
                <h4 class="manage-user__settings-link" role="button" @click="$router.push('/projects')"><span class="manage-user__settings-link--arrow"><img src="../../assets/icon_arrow_left.svg" alt=""></span> {{$t('projects.users_table.projects')}}</h4>
                <div class="d-flex flex-column">
                    <p class="mb-0" style="font-weight: 600;">{{activeUser.name}}</p>
                    <p class="mb-0" style="text-align: right;">{{activeUser.role}}</p>
                </div>
            </div>
            <div style="overflow-x:auto; padding-bottom: 60px; min-width: 60rem;">
                <div class="d-flex flex-row project__usersTable--header justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                        <h5 class="m-0">{{$t('projects.users_table.users')}}</h5>
                        <BaseBadge status="counter" :value="users.length" class="ml-2"/>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                        <b-form-input id="input-default" v-model="searchInput" placeholder="Search" style="width: fit-content;" class="height-40"></b-form-input>
                        <button @click="modals.Invite = true" class="ml-2 button-accent500 button-borderRadius-8 height-40" style="padding-left: 12px; padding-right: 12px; font-weight: 500;">{{$t('projects.users_table.Add_user')}}</button>
                    </div>
                </div>
                <b-table-simple v-if="!loading" id="my-table" :per-page="perPage" :current-page="currentPage" class="project__usersTable" style="overflow-x:auto;">
                    <b-thead>
                        <b-tr class="topicModelingTable__table__head" style="line-height: 1.5;">
                            <b-th class="align-middle">
                                <b-form-checkbox
                                    v-model="allSelected"
                                    aria-describedby="users"
                                    aria-controls="users"
                                    @change="toggleAll"
                                    class=""
                                    >
                                </b-form-checkbox>
                            </b-th>
                            <b-th class="topicModelingTable__table__heading dig-font-weight-medium project__usersTable--td-Width">{{$t('projects.users_table.name')}}</b-th>
                            <b-th class="topicModelingTable__table__heading dig-font-weight-medium project__usersTable--td-Width">{{$t('projects.users_table.email_address')}}</b-th>
                            <b-th class="topicModelingTable__table__heading dig-font-weight-medium project__usersTable--td-Width">{{$t('projects.users_table.status')}}</b-th>
                            <b-th class="topicModelingTable__table__heading dig-font-weight-medium project__usersTable--td-Width">{{$t('projects.users_table.role')}} <span class="ml-1" role="tooltip" title="I ruoli possono essere:"><img src="../../assets/icon_question_mark.svg" alt=""></span></b-th>
                            <b-th class="topicModelingTable__table__heading dig-font-weight-medium project__usersTable--td-Width"> <span role="button" @click="dateFilter()">{{$t('projects.users_table.added_date')}} <img class="ml-1" src="../../assets/icon_arrow_down.svg" alt="" style=""></span></b-th>
                            <b-th class="topicModelingTable__table__heading dig-font-weight-medium project__usersTable--td-Width">{{$t('projects.users_table.projects')}}</b-th>
                            <b-th></b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="user in usersInTable" :key="user.id">
                            <b-td class="align-middle">
                                <b-form-checkbox v-if="user.status != 'INACTIVE'" v-model="selected" :value="user" ></b-form-checkbox>
                            </b-td>
                            <b-td class="align-middle project__usersTable--nameColumn"><span v-if="user.status != 'PENDING' && user.status != 'INACTIVE'">{{user.name}}</span></b-td>
                            <b-td class="align-middle">{{user.email}}</b-td> 
                            <b-td class="align-middle">
                                <BaseBadge :status="user.status"/>
                            </b-td> 
                            <b-td class="align-middle">
                                <div class="">
                                    <!-- <select @change="setRole($event, user)" v-model="user.role" :disabled="user.status != 'ACTIVE'">
                                        <option v-for="role in roles" class="adminModal__checkboxList" :key="role.id" :value="role.name">{{role.name}}</option>
                                    </select> -->
                                </div>
                                <b-form-select @input.native="setRole($event, user)" v-model="user.role" :disabled="user.status != 'ACTIVE'">
                                    <b-form-select-option v-for="role in roles" :key="role.id" :value="role.name">{{role.name}}</b-form-select-option>
                                </b-form-select>
                            </b-td>
                            <b-td class="align-middle">
                                {{user.created_at}}
                            </b-td> 
                            <b-td class="align-middle">
                                <div class="d-flex">
                                    <div style="position: relative" @click="modals.Assign = true, activeProjectUser=user">
                                        <BaseBadge status="project" value="" class="mr-2" addAction/>
                                        <!-- <div style="position: absolute; display: unset;">
                                            show
                                            <b-form-select  v-model="org_projects_selected" :options="org_projects" multiple :select-size="4"></b-form-select>
                                        </div> -->
                                    </div>

                                    <BaseBadge v-for="project in user.user_projects.slice(0, 3)" status="project" :value="project.name" class="mr-2" :title="project.name"/>
                                    <BaseBadge status="project" v-if="user.user_projects.length > 2" :value="'+' + (user.user_projects.length - 3)"/>
                                </div>
                            </b-td>
                            <b-td>
                                <button v-if="user.status != 'INACTIVE'" type="button" class="qa__tab--buttonIcon-delete" title="Disable this user" @click="disableSingleUser(user.id)"></button>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>   
                <b-skeleton-table
                    v-if="loading"
                    :rows="8"
                    :columns="6"
                    :table-props="{ bordered: true, striped: true }"
                ></b-skeleton-table> 
                <b-pagination
                    v-if="paginationIsVisible"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="justify-content-center flex-end"
                ></b-pagination>
            </div>  
        </div>
    </div>
    </div>
    
</template>

<script>
import BaseBadge from '../base/BaseBadge.vue'
import InviteUserModal from './InviteUserModal.vue'
import BaseActionBar from '../base/BaseActionBar.vue'
import AssignProjectModal from './AssignProjectModal.vue'
import ConfirmModal from "./ConfirmModal.vue"
import axios from 'axios'

export default {
    name: "TheManageUsers",

    components:{
        ConfirmModal,
        BaseBadge,
        InviteUserModal,
        BaseActionBar,
        AssignProjectModal
    },

    data(){
        return{
            selected: {
                userToDelete: null
            },
            activeProjectUser: null,
            activeUser: null,
            paginationIsVisible: true,
            users: [],
            loading: true,
            roles: [],
            searchInput: "",
            perPage: 10,
            currentPage: 1,
            allSelected: false,
            selected: [],

            modals: {
                Invite: false,
                ConfirmDelete: false,
                ConfirmMultiDelete: false,
                Assign: false,
            },

            inputField: "",
            filteredUsersByDate: false,
        }
    },

    computed: {
        DisplayActionBar() {
            // Returns true if at least one user is selected, false otherwise
            return this.selected.length > 0;
        },
        rows() {
            // Returns the total number of users
            return this.users.length
        },
        usersInTable() {
            // Calculate the starting and ending indices for the current page
            let start = (this.currentPage - 1) * this.perPage;
            let end = start + this.perPage;

            // Filter users based on the search input
            let filteredUsers = this.filteredUsers.filter(user => user.name.toLowerCase().startsWith(this.searchInput.toLowerCase()));

            // Update the pagination visibility based on the number of filtered users
            if(filteredUsers.length <= this.perPage)
                this.paginationIsVisible = false;
            else
                this.paginationIsVisible = true;

            // Return the users to be displayed on the current page
            return filteredUsers.slice(start, end);
        },

        filteredUsers() {
            // If filteredUsersByDate is true, return the users sorted by creation date
            if (this.filteredUsersByDate) {
                const sortedUsers = [...this.users];
                sortedUsers.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                return sortedUsers;
            } else {
                // Otherwise, return the original list of users
                return this.users;
            }
        }
    },
    methods: {
        disableSingleUser(id){
            this.selected.userToDelete = id
            this.modals.ConfirmDelete = true;
        },

        getActiveUser(){
            // Get the active user from the Vuex store
            this.activeUser = this.$store.getters.activeUser;
        },


        //Disable user/users
        
        multiDisable(){
            // Delete all selected users
            this.selected.forEach(selected => {
                this.deleteUser(selected.id)
            });
            // Deselect all users
            this.toggleAll(false)
        },

        async deleteUser(id){
            let deleted = false
            // Delete the user with the given ID
            await axios.delete('/user/' + id)
                .then(function (response) {
                    deleted = response
                })
                .catch(function (error) {
                    console.log(error);
                });
            // Update the users data if the user was deleted
            if (deleted){
                this.UsersDataUpdate()
            }
        },

        async getUsers(){
            this.loading = true
            // Fetch users from the API
            await axios.get(`/organization/${this.activeUser.org_id}/users`).then(res => {
                let res1 = res.data.data
                this.loading = false
                // Format the user's created_at date
                res1.forEach(user => {
                    let date = new Date(user.created_at);
                    user.created_at = date.toLocaleDateString();
                }); 
                // Update the users data
                this.users = res1
            });
        },

        dateFilter(){
            // Toggle the date filter for sorting users
            this.filteredUsersByDate = !this.filteredUsersByDate;
            console.log("Filter: " , this.filteredUsersByDate)
        },

        selectedRoleId(name){
            // Find and return the role ID for the given role name
            let item = this.roles.find(obj => obj.name === name);
            if(item){
                return item.id
            }
        },

        toggleAll(checked) {
            // Select or deselect all users based on the checked parameter
            this.selected = checked ? this.users.filter(user => user.status !== 'INACTIVE') : []
            this.allSelected = checked ? true : false
        },

        async getRolesList(){
            // Fetch the list of roles from the API
            await axios.get('/roles')
            .then(response => {
                let roles = response.data.data
                this.roles.push(roles[1], roles[2])
            })
            .catch(error => {
                console.log(error);
            });
        },
        async authorizeUser() {
            let token;
            // Get the JWT token from localStorage, sessionStorage, or cookies
            if (localStorage || sessionStorage) {
                token = localStorage.getItem("jwt") ? localStorage.getItem("jwt") : sessionStorage.getItem("jwt");
            } else {
                token = cookies.getCookie("jwt");
            }
            // Set the default authorization header for Axios requests
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        },

        RoleRequest(users, role){
            // Create a request body with an array of user IDs and the specified role
            let body = {
                users: [],
                role: role
            }
            // Add the user ID to the users array in the request body
            const multiSelection = Array.isArray(users) 
            if (multiSelection){
                users.map( user => {
                    body.users.push(user.id)
                })
            } else {
                body.users.push(users.id)
            }

            // Send a POST request to the API to update the user's role
            axios.post('/users/role', body).then(
                response => {
                    // Log the successful response
                    if(multiSelection)
                        this.UsersDataUpdate()
                }
            ).catch(
                error => {
                    console.log(error)
                    alert(error)
                    this.UsersDataUpdate()
                }
            )
        },

        setRole(event, user){
            let name = event.target.value
            let role = this.selectedRoleId(name)
            this.RoleRequest(user, role)
        },

        UsersDataUpdate(){
            this.getUsers();
        },
    },

    provide() {
        return {
            modalShow: this.modalShow
        };
    },

    async created() {
        await this.authorizeUser();
        this.getActiveUser();
        await this.getRolesList();
        await this.getUsers();
    },

    async mounted() {
        // await this.getRolesList();
        // await this.getUsers();
    }
}
</script>

<style lang="scss" scoped>


.page-link {
  display: none !important;
}

select {
  font-size: 14px;
  color: #667085;
  border: none;
}

table {
  margin-bottom: 32px;
  color: #212529;
  border-collapse: separate;
  border: 1px solid #DADADA;
  border-radius: 0 0 10px 10px;
  border-spacing: 0;
  border-top: none;

  thead th {
    border-bottom: none;
  }
}

th {
  background-color: #F9FAFB;
}

tr {
  color: #667085;
}

.project__container {
  text-align: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10rem;
}

.project {
  &__usersTable {
    width: auto;

    &--td-Width {
      min-width: 11.5rem;
      max-width: 15rem;
    }

    &--nameColumn {
      color: #101828;
    }

    &--header {
      height: 84px;
      align-items: center;
      padding: 16px 22px;
      border: 1px solid #DADADA;
      border-bottom: none;
      border-radius: 10px 10px 0 0;
    }
  }
}

.base__Badge-Counter {
  padding: 2px 8px;
  width: 38px;
  height: 22px;
  /* align-items: center; */
  text-align: center;
  border-radius: 16px;
  color: #6941C6;
  background: #F9F5FF;
  font-weight: 500;
}

.qa__tab--buttonIcon-delete {
  opacity: 0.5;
  transition: 0.2s ease;

  &:hover {
    transition: 0.2s ease;
    opacity: 1;
  }
}

.manage-user {
  &__settings-link {
    &--arrow {
      margin-right: 1rem;
      transition: all 0.15s ease;
    }

    transition: all 0.15s ease;

    &:hover {
      transition: all 0.15s ease;
      color: var(--accent500);

      .manage-user__settings-link--arrow {
        margin-right: 0.6rem;
        transition: all 0.15s ease;
        filter: opacity(0.6);
      }
    }
  }
}

</style>