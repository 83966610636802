import { render, staticRenderFns } from "./BaseActionBar.vue?vue&type=template&id=37f54777&scoped=true&lang=true&"
import script from "./BaseActionBar.vue?vue&type=script&lang=js&"
export * from "./BaseActionBar.vue?vue&type=script&lang=js&"
import style0 from "./BaseActionBar.vue?vue&type=style&index=0&id=37f54777&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f54777",
  null
  
)

export default component.exports