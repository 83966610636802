const utils = {
  stringToBoolean(stringifiedBoolean) {
    return stringifiedBoolean === "true";
  },

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  async poll(fn, fnCondition, ms) {
    let result = await fn();
    while (fnCondition(result)) {
      await this.wait(ms);
      result = await fn();
    }
    return result;
  },

  wait(ms = 1000) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  },

  updateBody(isDarkMode) {
    if (isDarkMode) {
      document.body.style.backgroundColor = "#191919";
    } else {
      document.body.style.backgroundColor = "#FFFFFF";
    }
  },

  /**
   * Fix the Webpack import of encrypted SVG from SCSS.
   * @param {string} string - Remove extra double quotes from the beginning and the end of the icon variable string.
   */
  fixSvgImport(string) {
    return string.replace(/"/g, "");
  }
};
1;

export default utils;